import Loadable from "app/components/Loadable";

import { lazy } from "react";

//Added dealership - Fouzia
const AttendanceList = Loadable(lazy(() => import("./attendanceList")));
const AttendanceEditForm = Loadable(lazy(() => import("./attendance-edit/attendanceEditForm")));
const PayRollReport = Loadable(lazy(() => import("./payRollList")));

const attendanceRoutes = [
  { path: "/attendance/clock-list", element: <AttendanceList /> },
  { path: "/attendance/edit-attendance", element: <AttendanceEditForm /> },
  { path: "/attendance/attendance-list", element: <PayRollReport /> },
];

export default attendanceRoutes;
