import Loadable from "app/components/Loadable";

import { lazy } from "react";
import InvoiceEditForm from "./invoice-edit/InvoiceEditForm";

//Added dealership - Fouzia
const InvoiceList = Loadable(lazy(() => import("./InvoiceList")));
const InvoiceForm = Loadable(lazy(() => import("./invoice-form/InvoiceForm")));
const InvoiceViewer = Loadable(lazy(() => import("./invoice-viewer/InvoiceViewer")));
const ArchivedInvoiceList = Loadable(lazy(() => import("./ArchivedInvoiceList")));

const pagesRoutes = [
  { path: "/invoice/invoice-list", element: <InvoiceList /> },
  { path: "/invoice/add", element: <InvoiceForm /> },
  { path: "/invoice/edit-invoice", element: <InvoiceEditForm /> },
  { path: "/invoice/view-invoice", element: <InvoiceViewer /> },
  { path: "/invoice/archived-invoice-list", element: <ArchivedInvoiceList /> },

];

export default pagesRoutes;
