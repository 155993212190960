import { Button, Card, Grid, styled, Typography } from "@mui/material";
import { firestore } from "app/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// STYLED COMPONENTS
const ContentBox = styled("div")({
  alignItems: "center",
  flexDirection: "column",
  display: "flex",
  marginBottom: "16px",
});

const PreviewContainer = styled("div")(({ theme }) => ({
  width: '100%',
  height: '300px',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: '16px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: "13px",
  color: theme.palette.text.primary,
  marginTop: '8px',
  // ":hover": { background: "transparent" }
}));

export default function CustomerDocuments() {
  
  const [userInfo, setUserInfo] = useState([]);
  const [userDocuments, setUserDocuments] = useState({ssnFiles:[],licenseFiles:[],otherFiles:[]});

  const query = new URLSearchParams(window.location.search);
  const userID = query.get('uID');

  const navigate = useNavigate();

  const getUserInfo = async () => {

    try {
      const docRef = doc(firestore, "Users", userID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        //console.log(docSnap.data());
        setUserInfo(docSnap.data());

        const custom_obj = {
          ssnFiles: docSnap.data().ssnFiles || [],
          licenseFiles: docSnap.data().licenseFiles || [],
          otherFiles : docSnap.data().otherFiles || [],
        }

        //console.log(custom_obj);

        setUserDocuments(custom_obj);
        

      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }

    } catch (error) {
      console.log("ProductView [Error]", error);
    }

  }

  useEffect(() => {

    
    if(userID)
    {
      if(userID === "")
      {
        navigate('/pages/customer-list');
      }
      else if(userID === undefined)
      {
        navigate('/pages/customer-list');
      }
      else
      {
        getUserInfo(userID);
      }  
    }
    else
    {      
      navigate('/pages/customer-list');
    }
    
  
  }, []);


  const checkUrl = (fileURL) => {
    try {

      let is_image = false;

      // const response = await fetch(fileURL, { method: 'HEAD' }); // Use HEAD request to get headers only
      // const contentType = response.headers.get('Content-Type');
      // console.log("contentType", contentType);

      // if (contentType) {
      //   if (contentType.startsWith('image/')) {
      //     return "image";
      //   } else {
      //     return "file";
      //   }
      // } else {
      //   return "file";
      // }

      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'heic', 'heif', 'svg'];
      let extension = fileURL.split('.').pop().toLowerCase();
      extension = extension.split('?');
      //console.log("extension", extension[0]);

      //console.log(imageExtensions.includes(extension[0]));
      if(imageExtensions.includes(extension[0]))
      {
        is_image = true;
      }  

      return is_image;

    } catch (error) {
      return false;
      //return "file";
    }
  };


  const checkHeic = (fileURL) => {
    try {

      let is_image = false;

      const imageExtensions = ['heic', 'heif'];
      let extension = fileURL.split('.').pop().toLowerCase();
      
      extension = extension.split('?');

      if(imageExtensions.includes(extension[0]))
      {
        is_image = true;
      }  

      return is_image;

    } catch (error) {
      return false;
    }
  };

  const renderDocumentPreviews = (files, label) => (
    <div>
      <Typography variant="h6" sx={{ mb: 2 }}>{label}</Typography>
      <Grid container spacing={2}>
        {files.map((file) => (

          <Grid item xs={12} sm={6} md={4} key={file.name} style={{textAlign:"center"}}>
             
            <PreviewContainer>
            <div style={{ width: "100%", marginLeft:"auto", marginRight:"auto", marginBottom: "10px", marginTop:"10px"}}>
              {checkUrl(file.url) === true ? checkHeic(file.url) === false? (<img src={file.url} style={{ width: '300px', height: '200px', objectFit:"scale-down" }} /> ) : 
              (<object
                data={file.url}
                type="image/heic"
                style={{  width: '300px', height: '200px', objectFit:"scale-down" }}
               >
                <p>Your browser does not support displaying HEIC images directly.</p>
              </object>) :
              <iframe
                src={file.url}
                style={{  width: '300px', height: '200px', objectFit:"scale-down" }}
                frameBorder="0"
              ></iframe> }
              <br/>
              <a href={file.url} target="_blank"><h4 style={{textAlign:"center", margin:"5px 0"}}>{file.name}</h4></a>
              <StyledButton
                  variant="contained"
                  color="primary"
                  href={file.url}
                  target="_blank"
                  style={{color:"#FFF"}}
                  rel="noopener noreferrer"
                >
                  View Full Document
                </StyledButton>
             </div>
            </PreviewContainer>
          </Grid>
        ))}
      </Grid>
    </div>
  );

  return (
    <Card sx={{ pt: 3, px: 2, width: '100%' }} elevation={3}>
      {/* <ContentBox mb={3} alignContent="center">
        <Typography variant="h6" sx={{ mt: "16px", mb: "8px" }}>Customer Documents</Typography>
      </ContentBox> */}

      {/* <Divider sx={{ mb: 3 }} /> */}
      
      {userDocuments.ssnFiles.length > 0 ? renderDocumentPreviews(userDocuments.ssnFiles, 'SSN Documents'): ""}
      
      {userDocuments.licenseFiles.length > 0 ? renderDocumentPreviews(userDocuments.licenseFiles, 'License Documents'): ""}
      
      {userDocuments.otherFiles.length > 0 ? renderDocumentPreviews(userDocuments.otherFiles, 'Other Documents'): ""}
    </Card>
  );
}

