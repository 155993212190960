import Loadable from "app/components/Loadable";
import { lazy } from "react";

const ServiceList = Loadable(lazy(() => import("./serviceList")));
const ServiceAdd = Loadable(lazy(() => import("./service-form/serviceForm")));
const ServiceEdit = Loadable(lazy(() => import("./service-edit/serviceEdit")));

const servicesRoute = [
    { path: "/services/service-list", element: <ServiceList  /> },
    { path: "/services/add", element: <ServiceAdd  /> },
    { path: "/services/edit", element: <ServiceEdit  /> },
];
export default servicesRoute;
