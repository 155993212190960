import { Clear } from "@mui/icons-material";
import {
  Button,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FieldArray } from "formik";
import { Fragment } from "react";

const StyledCell = styled(TableCell)(() => ({ padding: 0 }));

export default function ContactPersonForm({ values, handleChange }) {

  const getRandomNumber = () => {

    try {
      const min = 1;
      const max = 100;
      const rand = min + Math.random() * (max - min);

      return rand;

    } catch (error) {
      
    }
  }

 

  return (
    <FieldArray name="contacts">
      {(arrayHelpers) => (
        <Fragment>
          <Table sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
              <TableCell colSpan={3} style={{display:"none"}}>Contract Number</TableCell>                
                <TableCell colSpan={3}>Contact Name</TableCell>
                <TableCell colSpan={2}>Job Title</TableCell>
                <TableCell colSpan={2}>Phone</TableCell>
                <TableCell colSpan={3}>Email Address</TableCell>
                <TableCell colSpan={2}>Send Invoice</TableCell>
                {/* <TableCell colSpan={3}>Contract Start Date</TableCell>
                <TableCell colSpan={3}>Contract End Date</TableCell> */}
                <TableCell colSpan={1}>Action?</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {values.contacts?.map((item, ind) => (
                <TableRow key={getRandomNumber()} sx={{ position: "relative" }}>
                  
                  <StyledCell colSpan={3} align="left" style={{paddingRight:"5px", display:"none"}}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Contract Number"
                      variant="outlined"
                      onBlur={handleChange}
                      name={`contacts[${ind}].contract_number`}
                      defaultValue={item.contract_number || ""}
                      
                    />
                  </StyledCell>


                  <StyledCell colSpan={3} align="left" style={{paddingRight:"5px"}}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Contact Name"
                      variant="outlined"
                      onBlur={handleChange}
                      name={`contacts[${ind}].contact_name`}
                      defaultValue={item.contact_name || ""}
                      
                    />
                  </StyledCell>

                  <StyledCell colSpan={2} align="left" style={{paddingRight:"5px"}}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Job Title"
                      variant="outlined"
                      onBlur={handleChange}
                      name={`contacts[${ind}].job_title`}
                      defaultValue={item.job_title || ""}
                      
                    />
                  </StyledCell>

                  <StyledCell colSpan={2} align="left" style={{paddingRight:"5px"}}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Phone"
                      variant="outlined"
                      onBlur={handleChange}
                      name={`contacts[${ind}].phone`}
                      defaultValue={item.phone || ""}
                    />
                  </StyledCell>

                  <StyledCell colSpan={3} align="left" style={{paddingRight:"5px"}}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Email"
                      variant="outlined"
                      onBlur={handleChange}
                      name={`contacts[${ind}].email`}
                      defaultValue={item.email || ""}
                    />
                  </StyledCell>

                  <StyledCell colSpan={2} align="left" style={{paddingRight:"5px"}}>
                  
                    <TextField
                    select
                    size="small"
                      onBlur={handleChange}
                      onChange={handleChange}
                      name={`contacts[${ind}].send_invoice`}
                      sx={{minWidth:"188px"}}
                      value={item.send_invoice || false}>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    
                  </StyledCell>

                  <StyledCell colSpan={3} align="left" style={{paddingRight:"5px", display:"none"}}>
                    <DatePicker
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Start Date"
                      onBlur={handleChange}
                      name={`contacts[${ind}].contract_start_date`}
                      defaultValue={item.contract_start_date || ""}
                      format="MM/dd/yyyy"
                    />
                  </StyledCell>

                  
                  <StyledCell colSpan={3} align="left" style={{paddingRight:"5px", display:"none"}} >
                    <DatePicker
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="End Date"
                      onBlur={handleChange}
                      name={`contacts[${ind}].contract_end_date`}
                      defaultValue={item.contract_end_date || ""}
                      format="MM/dd/yyyy"
                    />
                  </StyledCell>

                  <StyledCell colSpan={1} align="center">
                    <IconButton size="small" onClick={() => arrayHelpers.remove(ind)}>
                      <Clear color="error" fontSize="small" />
                    </IconButton>
                  </StyledCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Button
            sx={{ mt: 2 }}
            color="primary"
            variant="contained"
            onClick={() => arrayHelpers.push({})}>
            + Add Contact
          </Button>
        </Fragment>
      )}
    </FieldArray>
  );
}

const salutationList = ["Mr.", "Mrs.", "Ms.", "Miss.", "Dr."];
