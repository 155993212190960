// export const firebaseConfig = {
  // apiKey: "AIzaSyBzh7mGjvnDpOU50KFW0CY14-Sh4KrEw80",
  // authDomain: "autospade-admin.firebaseapp.com",
  // projectId: "autospade-admin",
  // storageBucket: "autospade-admin.appspot.com",
  // messagingSenderId: "530059177272",
  // appId: "1:530059177272:web:3c3849a61b4f68f176a5cd",
  // measurementId: "G-5WKX1B6KML"
// };

// export const auth0Config = {
//   client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
//   domain: process.env.REACT_APP_AUTH0_DOMAIN
// };



// firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyA-bHjCXadnYg0bXZmbl8NnwQCwk-xMIAE",
  authDomain: "auto-spade-production.firebaseapp.com",
  projectId: "auto-spade-production",
  storageBucket: "auto-spade-production.appspot.com",
  messagingSenderId: "1012250288409",
  appId: "1:1012250288409:web:8d590f1e8d66b70b52a588",
  measurementId: "G-4SQD7N05G2"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage();

// Initialize Firebase Functions
const functions = getFunctions(app);

export { firestore, functions, storage };
