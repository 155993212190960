import { Grid, MenuItem, TextField } from "@mui/material";
// GLOBAL CUSTOM COMPONENTS
import { countries } from "app/views/ecommerce/Country";

export default function AddressForm({ values, setFieldValue, handleChange }) {
  // const handleCheckboxChange = async ({ target: { checked } }) => {
  //   if (checked) setFieldValue("shipping", values.billing);
  //   else setFieldValue("shipping", {});
  // };

  return (
    
    <Grid container spacing={5}>
      <Grid item md={6} sm={12} xs={12}>
        {/* <Paragraph sx={{ pb: 2, fontWeight: 500, color: "text.secondary" }}>
          BILLING ADDRESS
        </Paragraph> */}

        <Grid container spacing={3} alignItems="center">
          

    
          <Grid item md={2} sm={4} xs={12}>
            Address
          </Grid>

          <Grid item md={10} sm={8} xs={12}>
            <TextField
              fullWidth
              multiline
              size="small"
              label="Address"
              variant="outlined"
              name="billing.address"
              value={values.billing?.address || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={2} sm={4} xs={12}>
            City
          </Grid>

          <Grid item md={10} sm={8} xs={12}>
            <TextField
              fullWidth
              label="City"
              size="small"
              variant="outlined"
              name="billing.city"
              value={values.billing?.city || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={2} sm={4} xs={12}>
            State
          </Grid>

          <Grid item md={10} sm={8} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="State"
              variant="outlined"
              name="billing.state"
              value={values.billing?.state || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={2} sm={4} xs={12}>
            Zip
          </Grid>

          <Grid item md={10} sm={8} xs={12}>
            <TextField
              fullWidth
              label="Zip"
              size="small"
              name="billing.zip"
              variant="outlined"
              value={values.billing?.zip || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={2} sm={4} xs={12}>
            Country
          </Grid>

          <Grid item md={10} sm={8} xs={12}>
            <TextField
              select
              fullWidth
              size="small"
              label="Country"
              variant="outlined"
              disabled={true}
              name="billing.country"
              value={values.billing?.country || "US"}
              onChange={handleChange}>
              {countries.map((item, ind) => (
                <MenuItem value={item.code} key={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item md={2} sm={4} xs={12}>
            Phone
          </Grid>

          <Grid item md={10} sm={8} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Phone"
              variant="outlined"
              name="billing.phone"
              value={values.billing?.phone || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={2} sm={4} xs={12}>
            Website
          </Grid>

          <Grid item md={10} sm={8} xs={12}>
            <TextField
              fullWidth
              label="Web URL"
              size="small"
              name="billing.website"
              variant="outlined"
              value={values.billing?.website || ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
