import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  styled
} from "@mui/material";
import { firestore, storage } from "app/firebase"; //firebase setup
import { Timestamp, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore"; // Import Firestore functionality
import { Formik } from "formik";
import { useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import * as yup from "yup";
// GLOBAL CUSTOM COMPONENTS
import Breadcrumb from "app/components/Breadcrumb";
import { H4 } from "app/components/Typography";
// LOCAL CUSTOM COMPONENTS
import OtherDetailsForm from "./components/OtherDetailsForm";
// GLOBAL CUSTOM HOOKS

import Loading from "app/components/MatxLoading";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import CustomerDocuments from "./components/CustomerDocuments";
import OtherDocumentsUpload from "./components/OtherDocumentsUpload";


// STYLED COMPONENTS
const Container = styled(Box)(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));


const Form = styled("form")(() => ({ padding: "16px" }));

const StyledTextField = styled(TextField)(() => ({ margin: "8px" }));



export default function CustomerEdit() {

  const [formValues, setFormValues] = useState({
    social_security_number: '',
    license: '',
    hourly_rate: '',
    contractStart: null,
    contractEnd: null,
    ongoingContract: false,
    otherDocuments: [],
  });

  const [tabIndex, setTabIndex] = useState(0);
  const [isloading, setisloading] = useState(false);
  const [updateEmployee, setUpdateEmployee] = useState(false);
  //const { createUserWithEmail} = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  
  //const tabList = ["Other Details", "Contact Persons"] //Removed Address Tab

  const tabList = ["Other Details", "Additional Documents", "Attached Documents"] //Removed Address Tab
  

  //const initialValues = { employeeType: "" };

  // const handleSubmit = async (values, { isSubmitting }) => {
  //   console.log(values);
  // };
  //const [dealerships, setDealerships] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dealerships, setDealershipsList] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userDealership, setUserDealership] = useState("");
  const [creaatedDate, setCreatedDate] = useState("");
  const [hourly_rate, setHourly_rate] = useState("");
  
  const [ssnFiles, setSSNFiles] = useState([]);
  const [licenseFiles, setLicenseFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);

  const [ssn, setSSN] = useState("");
  const [license, setLicense] = useState("");
  const [contractStartDate, setContractStartDate] = useState("");
  const [contractEndDate, setContractEndDate] = useState("");
  
  const employee_query = new URLSearchParams(window.location.search);
  const userID = employee_query.get('uID');

  const navigate = useNavigate();

  const handleContractChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleDocumentChange = (newFiles) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      otherDocuments: newFiles,
    }));
  };
 

  const fetchRolesData = async () => {
  try {
    
    const rolesData = [{id:0, name:"Super Admin"},{id:1, name:"Dealership Admin"},{id:2, name:"Autospade Employee"},{id:3, name:"Dealership Employee"},{id:4, name:"Dealership Inspector"}];

    // const rolesRef = doc(firestore, "Constants", "enums");
    // const rolesSnap = await getDoc(rolesRef);

    // if(rolesSnap.exists())
    //  {
    //   //console.log("rolesSnap.data().roles", rolesSnap.data().roles);
    //   if(rolesSnap.data().roles)
    //     {
    //       rolesData.push( rolesSnap.data().roles.autospade_employee);
    //       rolesData.push( rolesSnap.data().roles.dealership_admin);
    //       rolesData.push( rolesSnap.data().roles.dealership_employee);
    //       rolesData.push( rolesSnap.data().roles.dealership_inspector);
            
    //     }
    //  }
    
    return rolesData;
    
  } catch (error) {
    console.error("Error fetching roles:", error);
    return [];
  }
};

const customUploadFile = async(item, userId, folderName) => {
  try {

    return new Promise((resolve, reject) => {
      //console.log(item);
      const storageRef = ref(storage, `employees/${userId}/${folderName}/${item.name}`);          
      uploadBytes(storageRef, item).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((downloadURL) => {                
                
                resolve({
                  url : downloadURL,
                  name: item.name,
                });

              });                  
      });         
    
  });
    
  } catch (error) {
    
  }
}

const uploadSSnFiles = async(uploadFile, userId) => {
  var SSNFilesData = [];

  try {

    for(const item of uploadFile){

      SSNFilesData.push(await customUploadFile(item, userId, "ssn")) 
    }

    return SSNFilesData;
    
  } catch (error) {
    
  }

}

const uploadLicenseFiles = async(uploadFile, userId) => {
  var LicenseFilesData = [];

  try {

      for(const item of uploadFile){

        LicenseFilesData.push(await customUploadFile(item, userId, "license"));
        
        
      }

    return LicenseFilesData;
    
  } catch (error) {
    
  }

}

const otherDocumentsFiles = async(uploadFile, userId) => {
  var otherFilesData = [];

  try {

    for(const item of uploadFile){

      otherFilesData.push(await customUploadFile(item.file, userId, "otherDocuments"));
       
    }

    return otherFilesData;
    
  } catch (error) {
    
  }

}

const uploadAllFiles = async(selSSNFiles, selLicenseFiles, selOtherFiles, userId) => {

  try {
    
    const ssnUploadedFiles = await uploadSSnFiles(selSSNFiles, userId);
    const licenseUploadedFiles = await uploadLicenseFiles(selLicenseFiles, userId);
    const otherDocUploadedFiles = await otherDocumentsFiles(selOtherFiles, userId);
    
    return [ssnUploadedFiles, licenseUploadedFiles, otherDocUploadedFiles];
    
  } catch (error) {
    
  }


}

// Function to fetch roles data from Constants/enums subcollection
const handleSubmit = async (values, {resetForm, isSubmitting }) => {
  try {
    //console.log("clicked");
    setUpdateEmployee(true);
    setisloading(true);

    if(!(values.email === "" || values.email === undefined) && !(values.role === "" || values.role === undefined) && !(values.firstName === "" || values.firstName === undefined) && !(values.lastName === "" || values.lastName === undefined))
    {
      if(!(values.email === "" || values.email === undefined))
      {
        var updateEmployee = true;

        if(values.role !== 2 && (values.dlocation === "" || values.dlocation === undefined))
        {
          updateEmployee = false;
        }
        
        if(updateEmployee)
          {
            
 
                var dealershipID = values.dlocation || "";
                if(values.role === 2)
                {
                  //dealershipID = "";
                }

                var contractStart = Timestamp.now();

                if(values.contractStart !== "" && values.contractStart !== undefined && values.contractStart !== null)
                {
                  contractStart = new Date(values.contractStart);
                }

                var contractEnd = "";
                
                //console.log(values.contractEnd);

                if(values.contractEnd !== "" && values.contractEnd !== undefined && values.contractEnd !== null)
                  {
                    contractEnd = new Date(values.contractEnd);
                  }
                
                const [ssnUploadFilesData, licenseUploadFilesData, otherdocsUploadFilesData] = await uploadAllFiles(values.ssnFiles, values.licenseFiles, values.otherFiles, userID);
                var oldSSNFiles = [...ssnFiles];
                var oldLicenseFiles = [...licenseFiles];
                var oldOtherFiles = [...otherFiles];

                if(ssnUploadFilesData.length > 0)
                {
                  for(const item of ssnUploadFilesData){
                    oldSSNFiles.push(item);
                  }
                } 
                
                if(licenseUploadFilesData.length > 0)
                {
                  for(const item of licenseUploadFilesData){
                    oldLicenseFiles.push(item);
                  }
                } 
                
                if(otherdocsUploadFilesData.length > 0)
                {
                  for(const item of otherdocsUploadFilesData){
                    oldOtherFiles.push(item);
                  }
                }
                
                let is_disabled = false;
                //console.log("contractEnd", contractEnd);

                if(contractEnd !== "" && contractEnd !== undefined)
                {
                  is_disabled = true;
                }
                //console.log("is_disabled", is_disabled);  
              
                var customObj = {
                  id :userID,
                  name : values.firstName + " "+ values.lastName,
                  email : values.email,                  
                  role : values.role !== -1 ? values.role : 0,
                  dealership : dealershipID,
                  contract_start_date : contractStart,
                  contract_end_date : contractEnd,
                  is_disabled : is_disabled,
                  license:values.license,
                  device_tokens : [],
                  phone: values.workPhone,
                  mobile: values.mobile,
                  social_security_number: values.social_security_number,
                  created_date: creaatedDate,
                  hourly_rate: values.hourly_rate,
                  ssnFiles: oldSSNFiles,
                  licenseFiles: oldLicenseFiles,
                  otherFiles: oldOtherFiles,
                  daily_report: false,
                } ;

                //const docRef = await addDoc(collection(firestore, "Users"), customObj);
                //  console.log("Document written with ID: ", docRef.id);

                  await setDoc(doc(firestore, "Users", userID), customObj);

                  enqueueSnackbar("Employee Information Successfully Updated!", { variant: "success" });
                  if(is_disabled)
                  {
                    navigate('/pages/archived_customer-list');
                  }
                  else
                  {
                    navigate('/pages/customer-list');
                  }  
                  
               
          }
          else
          {
            enqueueSnackbar("DealerShip Required!", { variant: "error" });
          }
        
      }
      else
      {
        console.log("Email is required");
        enqueueSnackbar("Email is required!", { variant: "error" });  
      }

    }
    else
    {
      console.log("License is missing");
      enqueueSnackbar("License is missing", { variant: "error" });
    }  

    // const docRef = await addDoc(collection(firestore, "Users"), values);
    // console.log("Document written with ID: ", docRef.id);
    // //Added following lines to get collection id in fields

    // await setDoc(doc(firestore, "Users", docRef.id), { ...values, id: docRef.id });

    // console.log("Document updated with ID as a field.");
    
    setUpdateEmployee(false);
    setisloading(false);
    
    
  } catch (error) {
    setUpdateEmployee(false);
    setisloading(false);
    console.error("Error adding document: ", error);
    enqueueSnackbar(error.message, { variant: "error" }); 
  } finally {
    isSubmitting(false);
  }
};

const fetchRoles = async () => {
  setRoles(await fetchRolesData());
};

const getDealershipList = async () => {

  const querySnapshot = await getDocs(collection(firestore, "Dealerships"));
  const getDealership = querySnapshot.docs.map((doc, index) => {

    return{
      id: doc.id,
      name : doc.data().name,
      //services : doc.data().services,
    };

  });

  setDealershipsList(getDealership);    
}

const getEmployeeDetails = async () => {
  try {
    const userRef = doc(firestore, "Users",userID);
    const docSnap = await getDoc(userRef);

    if(docSnap.exists())
      {
        setUserInfo(docSnap.data());
        
        setFirstName(getUserFirstName(docSnap.data().name));
        setlastName(getUserlastName(docSnap.data().name));
        setUserRole(docSnap.data().role);
        setUserEmail(docSnap.data().email);
        setUserPhone(docSnap.data().phone || "");
        setUserMobile(docSnap.data().mobile || "");
        setUserDealership(docSnap.data().dealership || "");

        setSSN(docSnap.data().social_security_number || "");
        setLicense(docSnap.data().license || "");
        setContractStartDate(docSnap.data().contract_start_date?getDateTime(docSnap.data().contract_start_date) : "");
        setContractEndDate(docSnap.data().contract_end_date? getDateTime(docSnap.data().contract_end_date) : "");

        setCreatedDate(docSnap.data().created_date || Timestamp.now());

        setHourly_rate(docSnap.data().hourly_rate || "");

        setSSNFiles(docSnap.data().ssnFiles || []);
        setLicenseFiles(docSnap.data().licenseFiles || []);
        setOtherFiles(docSnap.data().otherFiles || []);

      }
    
  } catch (error) {
    
  }
}

const getUserFirstName = (userName) => {

  try {

     const fname = userName.split(" ");

     return fname[0];
    
  } catch (error) {
    
  }
}

const getUserlastName = (userName) => {

  try {

     const fname = userName.split(" ");

     return fname[fname.length - 1];
    
  } catch (error) {
    
  }
}

function getDateTime(currentDate)
{
  try {

    return new Date(currentDate.toDate()).toLocaleDateString("en-US");

    //return currentDate.toDate().toDateString().toLocaleString("en-US");
    
  } catch (error) {
    
  }
}

useEffect(() => {


  if(userID)
    {
      if(userID === "")
      {
        navigate('/pages/customer-list');
      }
      else if(userID === undefined)
      {
        navigate('/pages/customer-list');
      }
      else
      {
        getDealershipList();
        fetchRoles();

        getEmployeeDetails();
      }  
    }
    else
    {      
      navigate('/pages/customer-list');
    }

}, []);

      
  const handleTabChange = (e, value) => {
    setTabIndex(value);
  };

  const initialValues = {
    role: userRole,
    firstName: firstName,
    lastName: lastName,
    email: userEmail,
    workPhone: userPhone,
    mobile: userMobile,
    dlocation: userDealership,
    license: license,
    social_security_number: ssn,
    contractStart:contractStartDate,
    contractEnd:contractEndDate,
    hourly_rate: hourly_rate,
    ssnFiles: [],
    licenseFiles: [],
    otherFiles: []
  };
  
  return (
    <Container>
      {isloading? <Loading />:""}
      <Container style={{marginLeft:"0px"}}>
        <Breadcrumb routeSegments={[{ name: "Employees List", path: "/pages/customer-list" },{ name: "Edit Employee" }]} />
      </Container>

      <Card elevation={3}>
        <H4 p={2}>Edit Employee</H4>

        <Divider sx={{ mb: 1 }} />

        <Formik 
        initialValues={initialValues} 
        onSubmit={handleSubmit} 
        enableReinitialize={true}       
        validationSchema={employeeSchema}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setSubmitting,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>

             <Grid container spacing={3} alignItems="center">
                <Grid item md={2} sm={4} xs={12}>
                  Employee Role
                </Grid>

                <Grid item md={10} sm={8} xs={12}>
                  <StyledTextField
                      select
                      size="small"
                      name="role"
                      label="Employee Role"
                      variant="outlined"
                      sx={{ minWidth: 188 }}
                      value={values.role !== 0 ? values.role : -1 || ""}
                      disabled={false}
                      onChange={handleChange}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role  && errors.role }
                      >
                      {roles.map((role) => (
                         role.id !== 1 && role.id !== 4 ?
                      <MenuItem value={role.id !== 0 ? role.id: -1} key={role.name} >
                        {role.name}
                      </MenuItem> : ""
                      ))}
                    </StyledTextField>
                </Grid>
              

                <Grid item md={2} sm={4} xs={12}>
                  Full Name
                </Grid>

                <Grid item md={10} sm={8} xs={12}>
                  <Box m={-1} display="flex" flexWrap="wrap">
                    

                    <StyledTextField
                      size="small"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      value={values.firstName || ""}
                      onChange={handleChange}
                      error={Boolean(touched.firstName && errors.firstName )}
                      helperText={touched.firstName  && errors.firstName }
                    />

                    <StyledTextField
                      size="small"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      value={values.lastName || ""}
                      onChange={handleChange}
                      error={Boolean(touched.lastName && errors.lastName )}
                    helperText={touched.lastName && errors.lastName }
                    />
                  </Box>
                </Grid>

  
                <Grid item md={2} sm={4} xs={12}>
                  Employee Email
                </Grid>
                <Grid item md={10} sm={8} xs={12}>
                  <TextField
                    name="email"
                    size="small"
                    type="email"
                    variant="outlined"
                    value={values.email || ""}
                    label="Employee Email"
                    disabled={true}
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email  && errors.email }
                  />
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  Employee Phone
                </Grid>

                <Grid item md={10} sm={8} xs={12}>
                  <Box m={-1} display="flex" flexWrap="wrap">
                    <StyledTextField
                      size="small"
                      name="workPhone"
                      label="Work Phone"
                      variant="outlined"
                      value={values.workPhone || ""}
                      onChange={handleChange}
                    />
                    <StyledTextField
                      size="small"
                      name="mobile"
                      label="Mobile"
                      variant="outlined"
                      value={values.mobile || ""}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

             
                <Grid item md={2} sm={4} xs={12}>
                  Dealership Location
                </Grid>

                <Grid item md={10} sm={8} xs={12}>
                  <StyledTextField
                      select
                      size="small"
                      name="dlocation"
                      label="Location"
                      variant="outlined"
                      sx={{ minWidth: 188 }}
                      value={values.dlocation || ""}
                      onChange={handleChange}>
                      {dealerships.map((item, ind) => (
                        <MenuItem value={item.id} key={item.id} >
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                </Grid>
              </Grid>

              <Tabs
                value={tabIndex}
                textColor="primary"
                indicatorColor="primary"
                onChange={handleTabChange}
                sx={{ mt: 2, mb: 3 }}>
                {tabList.map((item, ind) => (
                  <Tab key={ind} value={ind} label={item} sx={{ textTransform: "capitalize" }} />
                ))}
              </Tabs>

              <div style={{display: tabIndex === 0? 'block': 'none'}}>
                <OtherDetailsForm values={values} handleChange={handleChange} handleContractChange={handleContractChange} />
              </div>

              <div style={{display: tabIndex === 1? 'block': 'none'}}>
                <OtherDocumentsUpload
                values={values}
                handleDocumentChange={handleDocumentChange}
                />
              </div>

              <div style={{display: tabIndex === 2? 'block': 'none'}}>
              <CustomerDocuments
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  
                />
              </div> 


              <Box mt={3}>
                <Button color="primary" variant="contained" type="submit" disabled={updateEmployee} >
                  Update Employee
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
}

const employeeSchema = yup.object().shape({
  role: yup.string().required("Role is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Please enter a valid email address").required("Email is required"),
});




//const roleList = ["Super Admin", "Dealership Admin", "AutoSpade Employee", "Dealership Employee", "Dealership Inspector"];
//const dealerList = ["Location 1", "Location 2", "Location 3", "Location 4", "Location 5"]
