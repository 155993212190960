import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { storage } from 'app/firebase'; // Import your Firebase storage instance
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function OtherDocumentsUpload ({values, handleChange}) {
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const storageRef = ref(storage); // Get reference to Firebase storage

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: async (acceptedFiles) => {
      const newFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const preview = URL.createObjectURL(file);
          //const downloadURL = await uploadFileToStorage(file);
          const downloadURL = "";
          return { file, preview, downloadURL };
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
  });

  const uploadFileToStorage = async (file) => {
    const fileRef = ref(storageRef, 'Users' + file.name); // Replace 'your-storage-folder/' with your storage folder
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  const handleRemoveFile = (fileToRemove) => {
    setFileToDelete(fileToRemove);
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    var tmpFiles = [...files]
    tmpFiles = files.filter((file) => file.file !== fileToDelete);
    setFiles(tmpFiles);
    setFileNames((prevNames) => {
      const newNames = { ...prevNames };
      //console.log(newNames);
      //console.log("fileToDelete", fileToDelete);
      delete newNames[fileToDelete.name];
      return newNames;
    });
    setDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setFileToDelete(null);
    setDialogOpen(false);
  };

  const handleFileNameChange = (file, newName) => {
    setFileNames({ ...fileNames, [file.name]: newName });
  };

  useEffect(()=>{
    
    values.otherFiles = files;

  },[files]);


  const renderFilePreview = (file) => (
    <Grid container spacing={2} alignItems="center" key={file.file.name}>
      <Grid item>
        <img
          src={file.preview}
          alt={file.file.name}
          style={{ width: '200px', height: '200px', objectFit: 'contain', marginRight: '10px', cursor: 'pointer' }}
          onClick={() => window.open(file.preview, '_blank')}
        />
      </Grid>
      <Grid item style={{display:"none"}} >
        <TextField
          label="Document Name"
          value={fileNames[file.file.name] || ''}
          onChange={(e) => handleFileNameChange(file.file, e.target.value)}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item>
        <Button onClick={() => handleRemoveFile(file.file)}>Remove</Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', borderRadius: '4px', textAlign: 'center' }}>
          <input {...getInputProps()} />
          <IconButton onClick={open}>
            <AddCircleOutlineIcon />
          </IconButton>
          <Typography>Click the plus icon to add documents</Typography>
        </div>
      </Grid>
      {files.map((file) => (
        <Grid item xs={12} key={file.file.name}>
          {renderFilePreview(file)}
        </Grid>
      ))}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete this document?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
