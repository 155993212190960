import { initializeApp } from "firebase/app";
import {
  getAuth,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

import { addDoc, collection } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
//import { firestore } from "./firebase";

export const firebaseConfig = {
  apiKey: "AIzaSyA-bHjCXadnYg0bXZmbl8NnwQCwk-xMIAE",
  authDomain: "auto-spade-production.firebaseapp.com",
  projectId: "auto-spade-production",
  storageBucket: "auto-spade-production.appspot.com",
  messagingSenderId: "1012250288409",
  appId: "1:1012250288409:web:8d590f1e8d66b70b52a588",
  measurementId: "G-4SQD7N05G2"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

// Initialize Firebase Functions
const functions = getFunctions(app);

export { auth, firestore, functions, storage };





// Function to generate a unique customer ID
// const generateCustomerId = () => {
//   return uuidv4();
// };

// // Function to update existing records with customer IDs
// const updateCustomerIds = async () => {
//   const customersCollection = collection(firestore, "employees");
//   const querySnapshot = await getDocs(customersCollection);
//   querySnapshot.forEach(async (doc) => {
//     // Check if the document already has a customer ID
//     if (!doc.data().customerId) {
//       // Generate a new customer ID
//       const customerId = generateCustomerId();
//       // Update the document with the new customer ID
//       await updateDoc(doc.ref, { customerId });
//     }
//   });
// };

// export { updateCustomerIds };

const addCustomer = async (customerData) => {
  // Generate a new customer ID
  const customerId = uuidv4();
  
  // Add the customer data along with the generated customer ID
  await addDoc(collection(firestore, "employees"), {
    ...customerData,
    customerId: customerId
  });

};

export { addCustomer };
