import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  MenuItem,
  styled,
  Tab,
  Tabs,
  TextField
} from "@mui/material";
import { firestore } from "app/firebase"; //firebase setup
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc, Timestamp } from "firebase/firestore"; // Import Firestore functionality
import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
// GLOBAL CUSTOM COMPONENTS
import Breadcrumb from "app/components/Breadcrumb";
import { H4 } from "app/components/Typography";
// LOCAL CUSTOM COMPONENTS
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddressForm from "./components/AddressForm";
import ContactPersonForm from "./components/ContactPersonForm";
import ServicesForm from "./components/ServicesForm";

// STYLED COMPONENTS
// const Container = styled("div")(({ theme }) => ({
//   margin: "30px",
//   [theme.breakpoints.down("sm")]: { margin: "16px" },
//   "& .breadcrumb": {
//     marginBottom: "30px",
//     [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
//   },
// }));

const Container = styled(Box)(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Form = styled("form")(() => ({ padding: "16px" }));

const StyledTextField = styled(TextField)(() => ({ margin: "8px" }));

const StyledInput = styled(TextField)({
  minWidth: "188px !important",
  "& label": { fontSize: "14px" },
  "& label.MuiInputLabel-shrink": { marginTop: "0px" },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": { fontSize: "14px", padding: "10px 14px !important" }
  }
});

const servicesList = [
  { name: "New car Prep", subServices: ["Inspection", "Cleaning"], price: "$100" },
  { name: "Used Car Prep", subServices: ["Inspection", "Repair"], price: "$150" },
  { name: "Service Wash", subServices: ["Exterior Wash", "Interior Cleaning"], price: "$50" },
  { name: "Courtesy Wash", subServices: ["Quick Wash"], price: "$25" },
];

const validationSchema = yup.object().shape({
  name: yup.string().required("Dealership name is required"),
  subservice: yup.array().min(1, "At least one service must be selected").required("Required"),
});


export default function DealerShipEdit() {
  const [tabIndex, setTabIndex] = useState(0);
  const [dealer, setDealerInfo] = useState({});

  const [templateServices, setTemplateServices] = useState([]);
  const [templateSubServices, setTemplateSubServices] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const dealer_query = new URLSearchParams(window.location.search);
  const dealershipID = dealer_query.get('dealerID');
  const [dealeralreadySelectedService, setdealeralreadySelectedService] = useState([]);

  const usedCARTasks = ["Used Car Detail - Complete Bumper to Bumper Detail.", "Pre-Install Ceramic & Sealants on Every Used Car.", "Bumper Paint- Front & Back Bumpers- (Paint the Bumpers Only)", "Paintless Dent Repair- Remove any Dings & Dents", "Wheel Repair- Repair damaged Wheels","Plastic Floor Mats - Install Plastic Floor Mats", "Courtesy Service Washing- Wash Service Customers Cars in Service Dept", "Sold Units- Perform White Glove Showroom Delivery" ];
  const newcarsTasks = ["New Car Detail-Remove Plastics & Stickers, Detail New Car Prep.", "Pre-Install Ceramic & Sealants on Every New Car", "New Car Get Ready- Ready for Showroom Display", "Sold Units- Perform White Glove Showroom Delivery"];

  const otherTasks = ["Body Shop Repairs- Body work & Paint Each Panel", "Interior Repair- Repair Major Rips, Scratches, Dye Per Car", "Lot Washing Service- Wash and Dry Inventory Per Service"];

  const CustomServiceData = [
    {id:0, name: "New Car Prep", 
     },
    {
    id:1, name: "Used Car Prep", 
    },
    {
      id:2, name: "Courtsey Wash", 
    },
    {
      id:3, name: "Service Wash", 
    },
  ];

  const CustomSubServiceData = [

      {amount: 100, description:"", name: "Standard Prep", tasks: [], service:0},
      {amount: 400, description:"", name: "Standard Prep", tasks: [], service:1},
      {amount: 400, description:"", name: "Rims", tasks: [], service:1},
      {amount: 300, description:"", name: "Front Bumber Repair", tasks: [], service:1},
      {amount: 200, description:"", name: "Back Bumber Repair", tasks: [], service:1},
      {amount: 0, description:"", name: "Standard", tasks: [], service:2},
      {amount: 100, description:"", name: "Standard", tasks: [], service:3},
      {amount: 0, description:"", name: "Gold", tasks: [], service:3},
      {amount: 0, description:"", name: "Premium", tasks: [], service:3},
    
  ];
  
  //const salutationList = ["Mr.", "Mrs.", "Ms.", "Miss.", "Dr."];
  const tabList = [ "Dealership Address", "Contact Persons", "Services"] //Removed Address Tab,  "Services Offered"

  const handleSubmit = async (values, {resetForm, isSubmitting }) => {
    try {

      var dealerAddress = "";
      var dealerCity = "";
      var dealerState = "";
      var dealerZipcode = "";
      var dealerCountry = "";
      var dealerWebsite = "";
      var dealerPhone = "";

      if(values.billing)
      {
        
        dealerAddress = values.billing.address ? values.billing.address : "" ;

        dealerCity = values.billing.city ? values.billing.city : "";
        dealerState = values.billing.state ? values.billing.state : "";
        dealerZipcode = values.billing.zip ? values.billing.zip : "";
        dealerCountry = values.billing.country ? values.billing.country : "US";
        dealerWebsite = values.billing.website ? values.billing.website : "";
        dealerPhone = values.billing.phone ? values.billing.phone : "";

        
      }

      //console.log("form values", values);

      var customServiceData = [];
      var customSubServiceData = [];

      if(values.services.length > 0)
      {
        values.services.forEach(service => {
          customServiceData.push({
            id: service.id,
            name: service.name,
            amount: service.amount || 0,
            inspection_required: service.inspection_required,
            is_fixed_amount: service.is_fixed_amount,
            photos_required: service.photos_required || false,
            is_disabled: service.is_disabled || false,
            job_fields: service.job_fields || {},
          });
        });

        //console.log("values.subservice [Submit]", values.subservice);
        if(values.subservice.length > 0)
          {
            values.subservice.forEach(sub_service => {
             
              const found = customServiceData.some(el => el.id === sub_service.service);
              //console.log(found);
              if(found){
                customSubServiceData.push(sub_service);
              }
            });
            
          }

      
        if(customServiceData.length > 0)
          {
            var contract_person = [];
            if(values.contacts)
            {
              if(values.contacts)
                {
                  contract_person = values.contacts;
                }
    
                var customObj = {
                  is_disabled: false,
                  name: values.name,
                  address: dealerAddress,
                  city: dealerCity,
                  country: dealerCountry,
                  phone:dealerPhone,
                  state: dealerState,
                  zipcode: dealerZipcode,
                  website: dealerWebsite,  
                  contact_person: contract_person,
                  services: customServiceData,
                  terms: values.terms,
                  group_name: values.group_name,
                  created_date: dealer.created_date || Timestamp.now(),         
                };
    
                console.log("customObj",customObj);
                
                await setDoc(doc(firestore, "Dealerships", dealershipID), customObj);

                //const docRef = await addDoc(collection(firestore, "Dealerships"), customObj);
                //console.log("Document written with ID: ", docRef.id);

                const subServiceRef = collection(firestore, 'Dealerships', dealershipID, 'SubServices');              
                const allDocs = await getDocs(subServiceRef);
                allDocs.docs.forEach(async(doc) => {
                  //console.log("doc", doc.id);
                  await deleteDoc(doc.ref);
                });
               
               
                if(customSubServiceData.length > 0)
                {
                  const newCollectionRef = collection(firestore, 'Dealerships', dealershipID, 'SubServices');
                  customSubServiceData.forEach(async (subservice) => {
                     await addDoc(newCollectionRef, subservice);                     
                   });
                  
                }
                
                //resetForm();

                enqueueSnackbar("Dealer Successfully updated", { variant: "success" }); 
                navigate('/pages/dealership-list');

            }
            else
            {
              enqueueSnackbar("Minimum 1 Contract person Information required", { variant: "error" }); 

            } 
          }
          else
          {
            enqueueSnackbar("Minimum 1 service required!", { variant: "error" }); 

          }                  
      }
      else
      {
        enqueueSnackbar("Please select at least one service", { variant: "error" }); 
        console.log("Service Page required");
      }

    } catch (error) {
      enqueueSnackbar(error, { variant: "error" }); 
      console.error("Error adding document: ", error);
    } finally {
      isSubmitting(false);
    }
  };

  const handleTabChange = (e, value) => {
    setTabIndex(value);
  };
      

  const getDealerSubService = async() => {
    try {
    
      const queryDealerShipDetails = await getDocs(collection(firestore, "Dealerships",dealershipID,'SubServices'));
      if(queryDealerShipDetails.docs)
      {
        var subServices = queryDealerShipDetails.docs.map(doc => {
          return doc.data();
        });

        const sortedSubServices = subServices.sort((a, b) => a.sort_order - b.sort_order);

        setdealeralreadySelectedService(sortedSubServices);

      }


    } catch (error) {
      
    }
  }

  const getDealerDetails = async() => {
    try {

      const dealerRef = doc(firestore, "Dealerships",dealershipID);
      const docSnap = await getDoc(dealerRef);

      if(docSnap.exists())
      {
        setDealerInfo(docSnap.data());
      }

      
    } catch (error) {
      
    }
  }

  const getServicesList = async() => {
    try {

      const docRef = doc(firestore, "Constants", "templates");        
      const docSnap = await getDoc(docRef);

      if(docSnap.exists())
      {
        //console.log("services1", docSnap.data());

        //setServices(docSnap.data());

        var customService = [];
        var customSubService = [];

        if(docSnap.data().services.length > 0)
        {

          const sortedItems = docSnap.data().services.sort((a, b) => a.id - b.id);

          sortedItems.forEach((item) => {
           
            var service_amount = 0;
  
            if(item.sub_services.length > 0)
            {
              item.sub_services.forEach((sub_service,index) => {

                service_amount+= sub_service.amount;
                let subserviceTasks = [];
                if(item.id === 0 && index === 0)
                {
                  subserviceTasks = newcarsTasks;
                }
                else if(item.id === 1 && index === 0)
                {
                  subserviceTasks = usedCARTasks;
                }    

                customSubService.push({
                  service: item.id,
                  id:index,
                  amount: sub_service.amount,
                  name: sub_service.name,
                  description: sub_service.description,
                  tasks: subserviceTasks,
                  sort_order: sub_service.sort_order || "",
                });
  
              });
  
            }

            let photos_required = false;
             
            customService.push({
              id: Number(item.id),
              name: item.name,
              is_disabled: false,
              amount: Number(service_amount),
              photos_required: photos_required,
              inspection_required: item.inspection_required,
              is_fixed_amount: item.is_fixed_amount,
              job_fields:item.job_fields,
            });
  
          });

          const sortedSubServices = customSubService.sort((a, b) => a.sort_order - b.sort_order);
         
          setTemplateServices(customService);
          setTemplateSubServices(sortedSubServices);
         
        }
        else
        {
          console.log("No data found");
        }

      }

    } catch (error) {
      console.log("getServicesList [Error]: ",error);
    }
  }
  

  useEffect(() => {
    

    if(dealershipID)
      {
        if(dealershipID === "")
        {
          navigate('/pages/dealership-list');
        }
        else if(dealershipID === undefined)
        {
          navigate('/pages/dealership-list');
        }
        else{

          getDealerSubService().then(() => {
            getDealerDetails();
          });

          getServicesList();
 
        }

        //console.log("dealershipID", dealershipID);
         
      }
      else
      {      
        navigate('/pages/dealership-list');
      }

  }, []);

  const initialValues = {
    name: dealer.name,
    group_name: dealer.group_name || "",
    subservice: dealeralreadySelectedService,
    billing: {
      address: dealer.address,
      city: dealer.city,
      state: dealer.state,
      country: dealer.country,
      zip: dealer.zipcode,
      phone: dealer.phone,
      website: dealer.website,
    },
    contacts: dealer.contact_person,
    services: dealer.services?dealer.services.sort((a, b) => a.id - b.id): [],
    terms: dealer.terms || "",
  };

  return (
    <Container>
      <Container style={{marginLeft:"0px"}}>
        <Breadcrumb routeSegments={[{ name: "Dealership List", path: "/pages/dealership-list" }, { name: "Edit Dealer" }]} />
      </Container>

      <Card elevation={3}>
        <H4 p={2}>Edit Dealer</H4>

        <Divider sx={{ mb: 1 }} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              
              <Grid container spacing={3} alignItems="center">
                <Grid item md={2} sm={4} xs={12}>
                  Dealership Name
                </Grid>
                <Grid item md={10} sm={8} xs={12}>
                  <Box m={-1} display="flex" flexWrap="wrap">
                    <StyledTextField
                      size="small"
                      name="name"
                      label=""
                      variant="outlined"
                      value={values.name || ""}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Box>
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  Group Name
                </Grid>
                <Grid item md={10} sm={8} xs={12}>
                  <Box m={-1} display="flex" flexWrap="wrap">
                    <StyledTextField
                      size="small"
                      name="group_name"
                      label="Group Name"
                      variant="outlined"
                      value={values.group_name}
                      onChange={handleChange}
                      error={Boolean(touched.group_name && errors.group_name)}
                      helperText={touched.group_name && errors.group_name}
                    />
                  </Box>
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  Dealership Terms
                </Grid>
                <Grid item md={10} sm={8} xs={12}>
                  <Box m={-1} display="flex" flexWrap="wrap">
                  <StyledInput
                      select
                      name="terms"
                      size="small"
                      label="Terms"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.terms || ""}
                      sx={{ m: "8px !important" }}>
                      {paymentTermList.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </StyledInput>
                  </Box>
                </Grid>
              </Grid>

              <Tabs
                value={tabIndex}
                textColor="primary"
                indicatorColor="primary"
                onChange={handleTabChange}
                sx={{ mt: 2, mb: 3 }}
              >
                {tabList.map((item, ind) => (
                  <Tab key={ind} value={ind} label={item} sx={{ textTransform: "capitalize" }} />
                ))}
              </Tabs>

              {tabIndex === 0 && <AddressForm values={values} handleChange={handleChange} />}



              {tabIndex === 1 && (
                <ContactPersonForm
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                />
              )}

              {/*tabIndex === 2 && <OtherDetailsForm values={values} setFieldValue={setFieldValue} handleChange={handleChange}/>*/}  

              {tabIndex === 2 && (
                
              <ServicesForm values={values} templateServices = {templateServices} templateSubServices ={templateSubServices} 
              setFieldValue={setFieldValue}
              handleChange={handleChange} />
            )} 


              <Box mt={3}>
                <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                  Update Dealer
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
}

const paymentTermList = [
  "NET 15",
  "NET 30",
];
