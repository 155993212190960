import Loadable from "app/components/Loadable";

import { lazy } from "react";

//Added dealership - Fouzia
const StatementList = Loadable(lazy(() => import("./statementList")));
const StatementForm = Loadable(lazy(() => import("./statement-form/statementForm")));
const StatementEditForm = Loadable(lazy(() => import("./statement-edit/statementEditForm")));
const StatementViewer = Loadable(lazy(() => import("./statement-viewer/statementViewer")));
const ArchivedStatementList = Loadable(lazy(() => import("./ArchivedstatementList")));

const statementRoutes = [
  { path: "/statement/statement-list", element: <StatementList /> },
  { path: "/statement/add", element: <StatementForm /> },
  { path: "/statement/edit-statement", element: <StatementEditForm /> },
  { path: "/statement/view-statement", element: <StatementViewer /> },
  { path: "/statement/archived-statement-list", element: <ArchivedStatementList /> },
];

export default statementRoutes;
