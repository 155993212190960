import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
// MUI DATE PICKER
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// ALL CONTEXT FILES
import { AuthProvider } from "app/contexts/FirebaseAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";
// THEME PROVIDER
import { MatxTheme } from "./components/MatxTheme";
// REDUX STORE
import store from "./redux/store";
// ALL ROUTES
import routes from "./routes";
// FAKE DATABASE
import "../fake-db";

export default function App() {
  const content = useRoutes(routes);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <SettingsProvider>
          <MatxTheme>
            <AuthProvider>{content}</AuthProvider>
          </MatxTheme>
        </SettingsProvider>
      </Provider>
    </LocalizationProvider>
  );
}
