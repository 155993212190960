import AuthGuard from "./auth/AuthGuard";
import Redirect from "./auth/Redirect";
import Account from "./views/account";
// import mapRoutes from "./views/map/map-routes";
// import ListRoute from "./views/list/list-route";
import crudRoute from "./views/CRUD/crud-routes";
import NotFound from "./views/sessions/NotFound";
// import todoRoutes from "./views/todo/TodoRoutes";
import formsRoutes from "./views/forms/forms-routes";
import inboxRoute from "./views/inbox/inbox-routes";
import pagesRoutes from "./views/pages/pages-routes";
// import chartsRoute from "./views/charts/charts-route";
import chatRoutes from "./views/chat-box/chat-routes";
// import pricingRoutes from "./views/pricing/pricing-routes";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import invoiceRoutes from "./views/invoice/invoice-routes";
import serviceRoutes from "./views/services/services-routes";
import sessionRoutes from "./views/sessions/session-routes";
// import calendarRoutes from "./views/calendar/calendar-routes";
import dashboardRoutes from "./views/dashboard/dashboard-routes";
// import ecommerceRoutes from "./views/ecommerce/ecommerce-routes";
// import materialRoutes from "./views/material-kit/MaterialRoutes";
// import dragAndDropRoute from "./views/Drag&Drop/drag-drop-routes";
import attendanceRoutes from "./views/attendance/attendance-routes";
import pageLayoutRoutes from "./views/page-layouts/page-layout-routes";
import statementRoutes from "./views/statement/statement-routes";
// import scrumBoardRoutes from "./views/scrum-board/scrum-board-routes";

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      // ...calendarRoutes,
      // ...chartsRoute,
       ...chatRoutes,
       ...crudRoute,
      // ...dragAndDropRoute,
      // ...ecommerceRoutes,
      ...formsRoutes,
      ...invoiceRoutes,
      ...serviceRoutes,
      // ...ListRoute,
      // ...mapRoutes,
      //  ...materialRoutes,
       ...inboxRoute,
      ...pageLayoutRoutes,
      ...pagesRoutes,
      ...statementRoutes,
      ...attendanceRoutes,
      // ...pricingRoutes,
      // ...scrumBoardRoutes,
      // ...todoRoutes,
      { path: "/page-layouts/account", element: <Account /> }
    ],
    dealer: [
      ...dashboardRoutes,
      ...pagesRoutes,
      { path: "/page-layouts/account", element: <Account /> }
    ],

  },
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
