import { Clear } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import Edit from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled
} from "@mui/material";
import { FieldArray } from "formik";
import { Fragment, useEffect, useState } from "react";

const StyledCell = styled(TableCell)(() => ({ padding: 0 }));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const StyledTextField = styled(TextField)(() => ({ margin: "8px" }));

export default function ServicesForm({values, templateServices, templateSubServices, handleChange }) {

  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);
  const [selectedSubServices, setselectedSubServices] = useState([]);
  const [selectedServiceID, setselectedServiceID] = useState(-1);

  const [selectedServiceName, setServiceName] = useState("");
  const [selectedInspectionRequired, setSelectedInspectionRequired ] = useState(false);
  const [selectedFixedAmount, setSelectedFixedAmount ] = useState(true);

  
  const [open, setOpen] = useState(false);

  const getRandomNumber = () => {

    try {
      const min = 1;
      const max = 100;
      const rand = min + Math.random() * (max - min);

      return rand;

    } catch (error) {
      
    }
  }

  const getSubServiceRandomNumber = (min, max) =>{
    try {
      
      const rand = min + Math.random() * (max - min);

      return rand;

    } catch (error) {
      
    }
  }
  
  const handleClickOpen = (ind) => {

    try {

      setSelectedServiceIndex(ind);

      setselectedServiceID(values.services[ind].id);
      setServiceName(values.services[ind].name);
      setSelectedInspectionRequired(values.services[ind].inspection_required);
      setSelectedFixedAmount(values.services[ind].is_fixed_amount);

      //console.log(values.subservice);

      var tmpSubServices = [];
      values.subservice.forEach(subService => {

        if(Number(values.services[ind].id) === Number(subService.service))
         {
          tmpSubServices.push(subService);
         } 

      });

      //console.log("tmpSubServices", tmpSubServices);

      setselectedSubServices(tmpSubServices);

      setOpen(true);
      
    } catch (error) {
      console.log("handleClickOpen [Error]", error);
    }

  };
  const handleClose = () => {

    values.currentService = null;

    var current_service_name = [...selectedServiceName];
    current_service_name = "";
    setServiceName(current_service_name);

    var currentSelectedIndex = selectedServiceIndex;
    currentSelectedIndex = null;
    setSelectedServiceIndex(currentSelectedIndex);
    
    var currentSelectedServiceID = selectedServiceID;
    currentSelectedServiceID = -1;
    setselectedServiceID(currentSelectedServiceID);

    var currentInsprectionRequired = selectedInspectionRequired;
    currentInsprectionRequired = false;
    setSelectedInspectionRequired(currentInsprectionRequired);

    var currentFixedAmount = selectedFixedAmount;
    currentFixedAmount = false;
    setSelectedFixedAmount(currentFixedAmount);

    var currentSelectedSubServices = [...selectedSubServices];
    currentSelectedSubServices = [];
    setselectedSubServices(currentSelectedSubServices);

    setOpen(false);
  };

  const removeCurrentRecord = (index, arrayHelpers, serviceId) => {
    try {
      
      console.log("serviceId", serviceId);
      // values.subservice.forEach((element, ind) => {
      //   if(Number(element.service) === Number(serviceId))
      //     {
      //       values.subservice.splice(ind);
      //     }
      // });

      arrayHelpers.remove(index);
      
    } catch (error) {
      
    }
  }

  const addNewService = (arrayHelpers) => {
    try {

      setBtnSelectService(true);

      let nextRowID = values.services.length;
      
      if(values.services.length === 0)
      {
        nextRowID = Number(values.services.length);
      }
      else
      {
        nextRowID = Number(values.services[values.services.length-1].id) + 1;
      }

      //console.log(nextRowID);

     const service_detail = templateServices.filter(templateService => templateService.id === (Number(currentSelectedServiceID) -1));
     const subservice_deatils = templateSubServices.filter(templateSubService => templateSubService.service === (Number(currentSelectedServiceID) -1));

     //console.log("service_detail", service_detail);
     //console.log("subservice_deatils", subservice_deatils);

     var currentSelectedSubservices = [...selectedSubServices];

     subservice_deatils.forEach(subService => {
      var new_subservice = {
        service: nextRowID,
        id: selectedSubServices.length,
        amount: subService.amount || "",
        name: subService.name || "",
        description: subService.description || "",
        tasks: subService.tasks || [],
        sort_order: subService.sort_order || "",
      };
      values.subservice.push(new_subservice);
      currentSelectedSubservices.push(new_subservice);
     });

     //console.log("After",currentSelectedSubservices);


     setselectedSubServices(currentSelectedSubservices);
      
      var custom_jobs_field = {
        customer_info: {enabled:true,mandatory:true},
        mileage: {enabled:true,mandatory:true},
        ro_number:{enabled:true,mandatory:true},
        stock_number:{enabled:true,mandatory:true},
        tag_number:{enabled:true,mandatory:true},
        vin_number:{enabled:true,mandatory:true},
      }

      arrayHelpers.push({ id: nextRowID,
        name: service_detail[0].name || "",
        is_disabled:service_detail[0].is_disabled || false,
        amount: service_detail[0].amount || 0,
        photos_required: service_detail[0].photos_required || false,
        inspection_required: service_detail[0].inspection_required || true,
        is_fixed_amount: service_detail[0].is_fixed_amount || false,
        job_fields: service_detail[0].job_fields || custom_jobs_field,
      });


      setSelectServicesOpen(false);

      setCurrentSelectedServiceID("");
      setcurrentArrayHelpers([]);
      setBtnSelectService(false);

    } catch (error) {
      console.log(error);
    }
  }

  const getSubserviceTotal  = (serviceID) => {
    try {

      var total_subServices = 0;

      values.subservice.forEach(subService => {

        if(Number(subService.service) === Number(serviceID))
          {
            total_subServices++;
          }

      });

      return total_subServices;

    } catch (error) {
      
    }
  }

  const getSubserviceTaskList = (index) => {
    try {

      let taskString = "";
      if(selectedSubServices[index].tasks.length > 0)
      {
        selectedSubServices[index].tasks.forEach(taskList => {

          if(taskString === "")
          {
            taskString = taskList;
          }
          else
          {
            taskString += ", "+taskList;
          }  

        });

      }
      
      return taskString;
      
    } catch (error) {
      
    }
  }

  const updateInformation = (selectedServiceID, event, handleClose) => {
    try {

        if((values.currentService !== undefined && values.currentService !== null) || (values.subservices !== undefined && values.subservices !== null))
        {

          if(values.currentService !== undefined && values.currentService !== null)
          {
            const currentServiceName = values.currentService[0].name;
            const current_is_fixed_amount = values.currentService[0].is_fixed_amount === "true"? true: false;
            const current_inspection_required = values.currentService[0].inspection_required === "true" ? true : false;

            if(currentServiceName !== undefined && currentServiceName !== null && selectedServiceIndex !== null)
            {
              values.services[selectedServiceIndex].name = currentServiceName;
            }
            
            if(current_is_fixed_amount !== undefined && current_is_fixed_amount !== null && selectedServiceIndex !== null)
            {
              values.services[selectedServiceIndex].is_fixed_amount = current_is_fixed_amount;
            }
            
            if(current_inspection_required !== undefined && current_inspection_required !== null && selectedServiceIndex !== null)
            {
              values.services[selectedServiceIndex].inspection_required = current_inspection_required;
            } 

          } 
            
        }

        //console.log("selectedServiceID", selectedServiceID);

        //console.log("subservice Before", values.subservice);

          var tmSubServices = [...values.subservice];

          const newArray = tmSubServices.filter((item, index) => Number(item.service) !== Number(selectedServiceID));

          values.subservice = [...newArray];
        
        //console.log("subservice After", values.subservice);


        console.log("selectedSubServices",selectedSubServices);
        
        if(selectedSubServices.length > 0)
        {
          var total_amount = 0;
          var total_subServices = 0;

          selectedSubServices.forEach(subItem => {
            
            if(subItem.name !== "")
            {
              total_subServices++;
              total_amount += Number(subItem.amount);

              values.subservice.push(subItem);
            }
          
          });

          //console.log("total_subServices", total_subServices);

          values.services[selectedServiceIndex].total_subservices = total_subServices;
          values.services[selectedServiceIndex].amount = total_amount;

          // Update Service Array
        }
        
        // values.subservice[index].name = subItem.name;
        // values.subservice[index].description = subItem.description;
        // values.subservice[index].amount = subItem.amount;
        // values.subservice[index].tasks = subItem.tasks;

      
      handleClose();

      values.currentService = null;

      var current_service_name = [...selectedServiceName];
      current_service_name = "";
      setServiceName(current_service_name);
  
      var currentSelectedIndex = selectedServiceIndex;
      currentSelectedIndex = null;
      setSelectedServiceIndex(currentSelectedIndex);
      
      var currentSelectedServiceID = selectedServiceID;
      currentSelectedServiceID = -1;
      setselectedServiceID(currentSelectedServiceID);
  
      var currentInsprectionRequired = selectedInspectionRequired;
      currentInsprectionRequired = false;
      setSelectedInspectionRequired(currentInsprectionRequired);
  
      var currentFixedAmount = selectedFixedAmount;
      currentFixedAmount = false;
      setSelectedFixedAmount(currentFixedAmount);
  
      var currentSelectedSubServices = [...selectedSubServices];
      currentSelectedSubServices = [];
      setselectedSubServices(currentSelectedSubServices);
      
    } catch (error) {

      console.log(error);

      //handleClose();
    }
  }

  const removeSelectedSubservices = (index, event) => {
    try {

      //console.log("currentRowIndex", index);

      var tmpSubservice = selectedSubServices;

      if(tmpSubservice.length > 0)
      {
        tmpSubservice.splice(index, 1);  
      }

      //console.log("tmpSubservice", tmpSubservice);

      setselectedSubServices([...tmpSubservice]);
  
    } catch (error) {
      console.log("removeSelectedSubservices", error.message);
    }
  }

  const addNewSubService = (event) =>{
    try {

      var currentSelectedSubservices = [...selectedSubServices];

      var new_subservice = {
        service: selectedServiceID,
        id:selectedSubServices.length,
        amount: 0,
        name: "",
        description: "",
        tasks: "",
        sort_order: "",
      };

      currentSelectedSubservices.push(new_subservice);
      setselectedSubServices(currentSelectedSubservices);
      
    } catch (error) {
      
    }
  }

  const updateSelectedService = (fieldName,index, event) => {
    try {

      //console.log("selectedSubServices", selectedSubServices);

      if(fieldName === "name")
      {
        selectedSubServices[index].name = event.target.value;
      }
      else if(fieldName === "description")
      {
        selectedSubServices[index].description = event.target.value;
      }
      else if(fieldName === "amount")
      {
        selectedSubServices[index].amount = Number(event.target.value);
      }
      else if(fieldName === "tasks")
      {
        var splitTasks = event.target.value.split(",");
        selectedSubServices[index].tasks = splitTasks;
      }
      else if(fieldName === "sort_order")
      {
        selectedSubServices[index].sort_order = Number(event.target.value);
      }    
      
    } catch (error) {
      
    }
  }


  // Delete Service
  const [deleteServiceDialogOpen, setDeleteServiceDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedRowServiceID, setselectedRowServiceID] = useState("");
  const [selectedArray, setselectedArray] = useState([]);

  // Delete Dealerships Callbacks
   const handleDeleteService = (index, arrayHelpers, serviceID) => {
    try {

      setselectedRowServiceID(-1);
      setselectedRowServiceID(serviceID);
      
      setSelectedIndex(-1);
      setSelectedIndex(index);
      
      setselectedArray([]);
      setselectedArray(arrayHelpers);

      setDeleteServiceDialogOpen(true);   
    } catch (error) {
      
    }
  }

  const handleCancelDeleteService = () => {
    try {

      setselectedRowServiceID(-1);
      setSelectedIndex(-1);
      setselectedArray([]);
      setDeleteServiceDialogOpen(false);
      
    } catch (error) {
      
    }
  }

  const handleConfirmDeleteService = async(index, arrayHelpers, serviceID) => {
    try {
      
      removeCurrentRecord(index, arrayHelpers, serviceID);

      var currentSelectedSubservices = [...selectedSubServices];

      console.log("before", currentSelectedSubservices);

      const subservice_deatils = currentSelectedSubservices.filter(currentSelectedSubservice => currentSelectedSubservice.service !== (serviceID));

      console.log("after", subservice_deatils);

      setselectedSubServices(subservice_deatils);

      var tmSubServices = [...values.subservice];
      const newArray = tmSubServices.filter((item, index) => Number(item.service) !== Number(serviceID));
      values.subservice = [...newArray];      
      console.log("newArray", newArray);

      console.log("updated values.subservice",values.subservice);
    

      setselectedRowServiceID(-1);
      setSelectedIndex(-1);
      setselectedArray([]);
      setDeleteServiceDialogOpen(false);
      
    } catch (error) {
      console.log("Error", error.message);
    }
  }


  // Delete Sub Services (Edit Service Popup)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedSubServiceIndex, setSelectedSubServiceIndex] = useState(-1);

  // Delete Dealerships Callbacks
   const handleDeleteSubService = (index, event) => {
    try {
      setSelectedSubServiceIndex(-1);
      setSelectedSubServiceIndex(index);
      setDeleteDialogOpen(true);   
    } catch (error) {
      
    }
  }

  const handleCancelDelete = () => {
    try {

      setSelectedSubServiceIndex(-1);
      setDeleteDialogOpen(false);
      
    } catch (error) {
      
    }
  }

  const handleConfirmDelete = async(index, event) => {
    try {
      
      removeSelectedSubservices(index, event);

      setSelectedSubServiceIndex(-1);
      setDeleteDialogOpen(false);
      
    } catch (error) {
      console.log("Error", error.message);
    }
  }

  useEffect(()=>{
    console.log("templateServices", templateServices);
    console.log("templateSubServices", templateSubServices);

  },[])

  // Select Service From Template
  const [selectServicesOpen, setSelectServicesOpen] = useState(false);
  const [btnSelectService, setBtnSelectService] = useState(false);
  const [currentArrayHelpers, setcurrentArrayHelpers] = useState([]);
  const [currentSelectedServiceID, setCurrentSelectedServiceID] = useState("");

  const selectTemplateService = (arrayHelpers) => {
    try {

      setSelectServicesOpen(true);
      setcurrentArrayHelpers(arrayHelpers);

    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectedServiceClose = () => {

    setSelectServicesOpen(false);
    setCurrentSelectedServiceID("");
    setcurrentArrayHelpers([]);

  };

  const handleSelectedServiceID = (e) => {
    try {
      setCurrentSelectedServiceID(e.target.value);
    } catch (error) {
      
    }
  }

  
  
  return (
    <FieldArray name="services">
      {(arrayHelpers) => (
          <Fragment>
                <Table sx={{ whiteSpace: "pre", minWidth: 750 }}>
                  <TableHead>
                    <TableRow>
                    <TableCell colSpan={3}>Service Name</TableCell>                
                      <TableCell colSpan={3}>Inspection Required</TableCell>
                      <TableCell colSpan={3}>Fixed Amount</TableCell>
                      <TableCell colSpan={3}>Total SubServices</TableCell>
                      <TableCell colSpan={3}>Service Amount</TableCell>
                      <TableCell colSpan={1}>Action?</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {values.services?.map((item, ind) => (
                      <TableRow key={getRandomNumber()} sx={{ position: "relative" }}>

                        <StyledCell colSpan={3} align="left" style={{paddingRight:"5px"}}>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            onBlur={handleChange}
                            name={`services[${ind}].name`}
                            defaultValue={item.name || ""}
                            disabled={true}
                            
                          />
                        </StyledCell>

                        <StyledCell colSpan={3} align="left" style={{paddingRight:"5px", paddingLeft:"5px"}}>

                        <RadioGroup
                            row
                            defaultValue={item.inspection_required || false}
                            name={`services[${ind}].inspection_required`}
                          
                          >
                            <FormControlLabel value="true"  disabled={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value="false"  disabled={true} control={<Radio />} label="No" />
                          </RadioGroup>
                      
                        </StyledCell>


                        <StyledCell colSpan={3} align="left" style={{paddingRight:"5px", paddingLeft:"5px"}}>

                        <RadioGroup
                            row
                            defaultValue={item.is_fixed_amount || false}
                            name={`services[${ind}].is_fixed_amount`}
                            
                          >
                            <FormControlLabel value="true" disabled={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" disabled={true} control={<Radio />} label="No" />
                          </RadioGroup>
                      
                        </StyledCell>

                        <StyledCell colSpan={3} align="left" style={{paddingRight:"5px"}}>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled={true}
                            onBlur={handleChange}
                            name={`services[${ind}].total_subservices`}
                            defaultValue={ getSubserviceTotal(item.id) || ""}
                          />
                        </StyledCell>

                        <StyledCell colSpan={3} align="left" style={{paddingRight:"5px"}}>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={true}
                            variant="outlined"
                            onBlur={handleChange}
                            name={`services[${ind}].amount`}
                            defaultValue={item.amount || "0"}
                          />
                        </StyledCell>

                        <StyledCell colSpan={1} align="center">
                        <IconButton size="small" onClick={() => handleClickOpen(ind)}><Edit color="primary" fontSize="small"/></IconButton>

                          <IconButton size="small" onClick={() => handleDeleteService(ind, arrayHelpers, item.id)}>
                            <Clear color="error" fontSize="small" />                            
                          </IconButton>
                        </StyledCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Button
                  sx={{ mt: 2 }}
                  color="success"
                  variant="contained"
                  onClick={() =>  selectTemplateService(arrayHelpers)}>
                  + Add Service
                </Button>


                <BootstrapDialog
                fullScreen
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                          >

                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                              Service Details
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            
                            </IconButton>
                            <DialogContent dividers>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item md={6} sm={6} xs={12}>
                                Service Name
                              </Grid>
                              <Grid item md={6} sm={6} xs={12}>
                                <Box m={-1} display="flex" flexWrap="wrap">

                                    <TextField
                                      
                                      size="small"
                                      variant="outlined"
                                      label="Service Name"
                                      onBlur={handleChange}
                                      name="currentService[0].name"
                                      defaultValue={selectedServiceName || ""}
                                      onChange={handleChange}
                                    />

                                </Box>
                              </Grid>

                              <Grid item md={6} sm={6} xs={12}>
                                Inspection Required
                              </Grid>
                              <Grid item md={6} sm={6} xs={12}>
                                <RadioGroup
                                  row
                                  defaultValue={selectedInspectionRequired || false}
                                  name="currentService[0].inspection_required"
                                  onChange={handleChange}
                                  onBlur={handleChange}
                                >
                                  <FormControlLabel value="true"  control={<Radio />} label="Yes" />
                                  <FormControlLabel value="false" control={<Radio />} label="No" />
                                </RadioGroup>

                              </Grid>

                              <Grid item md={6} sm={6} xs={12}>
                              Fixed Amount
                              </Grid>
                              <Grid item md={6} sm={6} xs={12}>
                                <RadioGroup
                                  row
                                  defaultValue={selectedFixedAmount||false}
                                  name="currentService[0].is_fixed_amount"
                                  onChange={handleChange}
                                  onBlur={handleChange}
                                >
                                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                  <FormControlLabel value="false" control={<Radio />} label="No" />
                                </RadioGroup>
                              </Grid>
                              
                              <Grid item md={12} sm={12} xs={12}>
                              Sub Services : {selectedSubServices.length}
                              </Grid>
                              <Grid item md={12} sm={12} xs={12}>
                                <Table sx={{ minWidth: 1200 }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell colSpan={3}>Name</TableCell>
                                      <TableCell colSpan={3}>Description</TableCell>
                                      <TableCell colSpan={2}>Amount</TableCell>
                                      <TableCell colSpan={3}>Tasks</TableCell>
                                      <TableCell colSpan={1}>SubService Order</TableCell>
                                      <TableCell colSpan={1}>Action?</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>                                                                       
                                    {selectedSubServices.map((subItem, index) => {
                                      return (                                       
                                          <TableRow key={getSubServiceRandomNumber(100,1000)}>
                                            <TableCell colSpan={3}>
                                              <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                name={`subservices[${index}].name`}
                                                defaultValue={subItem.name ||""}
                                                onChange={(event) => updateSelectedService("name", index, event)}
                                              />
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                              <TextField
                                                fullWidth
                                                multiline
                                                size="small"
                                                variant="outlined"
                                                name={`subservices[${index}].description`}
                                                defaultValue={ subItem.description ||""}
                                                onChange={(event) => updateSelectedService("description", index, event)}
                                      
                                              />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                              <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                name={`subservices[${index}].amount`}
                                                defaultValue={ subItem.amount || 0}
                                                onChange={(event) => updateSelectedService("amount", index, event)}
                                              />
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                              <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                name={`subservices[${index}].tasks`}
                                                defaultValue={getSubserviceTaskList(index) || ""}
                                                placeholder="add tasks list with (,)"
                                                onChange={(event) => updateSelectedService("tasks", index, event)}
                                            
                                              />
                                            </TableCell>
                                            <TableCell colSpan={1}>
                                              <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                name={`subservices[${index}].sort_order`}
                                                defaultValue={subItem.sort_order || ""}
                                                placeholder="Assign order"
                                                onChange={(event) => updateSelectedService("sort_order", index, event)}
                                            
                                              />
                                            </TableCell>
                                            <TableCell colSpan={1}>
                                            <IconButton size="small" onClick={(event) => handleDeleteSubService(index, event)}>
                                              <Clear color="error" fontSize="small" />                            
                                            </IconButton>
                                            </TableCell>
                                          </TableRow>
                                      );

                                    })}

                                  </TableBody>
                                </Table>

                                 <Button
                                  sx={{ mt: 2 }}
                                  color="primary"
                                  variant="contained"
                                  onClick={(event) => addNewSubService(event)}>
                                  + Add SubService
                                </Button>      

                              </Grid>

                              <Grid item md={12} sm={12} xs={12}>

                                  <Box mt={3}>

                                      <Button autoFocus 
                                        sx={{ mt: 2 }}
                                          color="success"
                                          variant="contained" onClick={(event)=>updateInformation(selectedServiceID, event, handleClose)}>
                                        Update Service
                                      </Button>


                                  </Box>

                              </Grid>
                              
                            </Grid>
                            </DialogContent>

                            <Dialog
                              open={deleteDialogOpen}
                              onClose={() => setDeleteDialogOpen(false)}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                            
                              <DialogTitle id="alert-dialog-title">
                                {`Are you sure you want to remove this SubService?`}
                              </DialogTitle>
                              <DialogActions>
                                <Button onClick={handleCancelDelete} color="primary">
                                  Cancel
                                </Button>
                                <Button onClick={(event) => handleConfirmDelete(selectedSubServiceIndex, event)} color="primary" autoFocus>
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>

                          </BootstrapDialog>

                          <Dialog
                            open={deleteServiceDialogOpen}
                            onClose={() => setDeleteServiceDialogOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                          
                            <DialogTitle id="alert-dialog-title">
                              {`Are you sure you want to remove this Service?`}
                            </DialogTitle>
                            <DialogActions>
                              <Button onClick={handleCancelDeleteService} color="primary">
                                Cancel
                              </Button>
                              <Button onClick={(event) => handleConfirmDeleteService(selectedIndex, selectedArray, selectedRowServiceID)} color="primary" autoFocus>
                                Delete
                              </Button>
                            </DialogActions>
                        
                          </Dialog>


                          <BootstrapDialog
                          fullWidth
                            onClose={handleSelectedServiceClose}
                            aria-labelledby="customized-dialog-title"
                            open={selectServicesOpen}
                          >

                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                              Select Services
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleSelectedServiceClose}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                              disabled={btnSelectService}
                            >
                              <CloseIcon color="info" />
                            
                            </IconButton>
                            <DialogContent dividers>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item md={6} sm={6} xs={12}>
                                Pick Services
                              </Grid>
                              <Grid item md={6} sm={6} xs={12}>
                                <Box m={-1} display="flex" flexWrap="wrap">
                                <Select
                                    labelId="dealership-select-label"
                                    label="Pick Services"
                                    size="small"
                                    onChange={(event) => handleSelectedServiceID(event)}
                                    value={currentSelectedServiceID||""}
                                    sx={{ minWidth: "188px" }}
                                  >
                                    {templateServices.map((service) => (
                                      <MenuItem key={service.name} value={Number(service.id)+1}>
                                        {service.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Box>
                              </Grid>


                              
                            </Grid>
                            </DialogContent>

                            <DialogActions>
                              <Button autoFocus onClick={(event)=> addNewService(currentArrayHelpers)} disabled={btnSelectService}  style={{color: "#fff",backgroundColor: "#2e7d32"}} >
                                Add
                              </Button>
                            </DialogActions>

                          </BootstrapDialog>

          </Fragment>
       )}

    </FieldArray>
  );
}
