import { Box, Button, Card, Checkbox, Divider, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, styled } from "@mui/material";
import { Formik } from "formik";
import { useEffect } from "react";

import { useSnackbar } from "notistack";
import * as yup from "yup";
// GLOBAL CUSTOM COMPONENTS
import Breadcrumb from "app/components/Breadcrumb";
import { FlexBox } from "app/components/FlexBox";
import { H4 } from "app/components/Typography";
// CUSTOM UTILS LIBRARY FUNCTIONS

import { firestore } from "app/firebase"; //firebase setup
import { and, collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore"; // Import Firestore functionality

// GLOBAL CUSTOM HOOKS
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Loading from "app/components/MatxLoading";
import useAuth from "app/hooks/useAuth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// STYLED COMPONENTS
const Container = styled(Box)(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

// const WebcamContainer = styled(Box)({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center"
// });

// const CaptureButton = styled(Button)({
//   marginBottom: "16px"
// });


// const StyledTextField = styled(TextField)({ marginBottom: "16px" });

// const Form = styled("form")({
//   paddingLeft: "16px",
//   paddingRight: "16px"
// });

const InvoiceRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: "16px",
  background: theme.palette.background.default
}));

const StyledInput = styled(TextField)({
  minWidth: "188px !important",
  "& label": { fontSize: "14px" },
  "& label.MuiInputLabel-shrink": { marginTop: "0px" },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": { fontSize: "14px", padding: "10px 14px !important" }
  }
});

const StyledDatePicker = styled(DatePicker)({
  margin: "8px !important",
  "& button": { padding: "6px" },
  "& label": { fontSize: "14px" },
  "& .MuiOutlinedInput-input": { fontSize: "14px", padding: "10px 14px !important" }
});

const StyledCell = styled(TableCell)({
  padding: 0
});


export const StyledTable = styled(Table)(({ theme }) => ({
  "& thead": {
    "& tr": {
      background: theme.palette.background.default,
      "& th": { paddingLeft: 0, paddingRight: 0 },
      "& th:first-of-type": {
        paddingLeft: "24px !important",
        [theme.breakpoints.down("sm")]: { paddingLeft: "16px !important" }
      }
    }
  },
  "& tbody": {
    "& tr": {
      "& td": { paddingLeft: 0, textTransform: "capitalize" },
      "& td:first-of-type": {
        paddingLeft: "24px !important",
        [theme.breakpoints.down("sm")]: { paddingLeft: "16px !important" }
      }
    }
  }
}));

var JobDetailsDocuments = [];
var jobAmountArray = [];
var dealerSubServices = [];

export default function InvoiceEditForm() { 
  const { user } = useAuth();

  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceDealer, setInvoiceDealer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceToDate, setInvoiceToDate] = useState("");
  const [invoiceFromDate, setInvoiceFromDate] = useState("");
  const [invoiceDiscount, setInvoiceDiscount] = useState(0);


  
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [defaultSelectedJobs, setDeafultSelectedJobs] = useState([]);

  const invoice_query = new URLSearchParams(window.location.search);
  const invoiceID = invoice_query.get('invoiceID');
  
  
  //var creator_info = {};

  const navigate = useNavigate();
  function getDateTime(currentDate)
  {
    try {

      return currentDate.toDate().toLocaleString("en-US");
      
    } catch (error) {
      
    }
  }

  const handleSubmit = async (values, {resetForm, isSubmitting }) => {
    
    try {

    //console.log(values);

    const selected_to_date = new Date(values.dueDate);
    const selected_from_date = new Date(values.invoiceDate);

    // console.log("selected_to_date", selected_to_date);
    // console.log("selected_from_date", selected_from_date);

    // console.log("selected_to_date (TimeStamp)", Timestamp.fromMillis(selected_to_date));
    // console.log("selected_from_date (TimeStamp)", Timestamp.fromMillis(selected_from_date));

    const customInvoiceObj = {
       dealership:  invoiceData.dealership,
       invoice_number: values.invoiceNo,
       total_amount: jobServicesAmount,
       generated_by: invoiceData.generated_by,
       invoice_duration: 1,
       created_date: invoiceData.created_date,
       from_date: selected_from_date,
       to_date: selected_to_date, 
       file_url: invoiceData.file_url,
       is_invoice_paid:invoiceData.is_invoice_paid,
       is_invoice_approved: invoiceData.is_invoice_approved,
       discount: Number(invoiceDiscount),
       partial_payment: invoiceData.partial_payment || {},
       is_disabled: invoiceData.is_disabled || false,
       is_statement_exist: invoiceData.is_statement_exist || false,
       statement_info: invoiceData.statement_info || {},
    };

    // console.log("customInvoiceObj",customInvoiceObj);
    // return "";

      if(selectedJobs.length > 0)
        {
          setButtonDisabled(true);

          console.log("customInvoiceObj",customInvoiceObj);
          
          await setDoc(doc(firestore,"Invoices",invoiceID), customInvoiceObj);

          //const docRef = await addDoc(collection(firestore, "Invoices"), customInvoiceObj);
          //console.log("Document written with ID: ", docRef.id);
          
          //enqueueSnackbar("Invoice Successfully created", { variant: "success" });
          
          if(await updateInvoiceDiscountInStatements(invoiceDiscount))
          {
            setButtonDisabled(false);

            navigate(`/invoice/view-invoice?invoiceID=`+invoiceID);

          }  
          
        }
        else{
          enqueueSnackbar("No Jobs Selected ", { variant: "error" });
        }
    

    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
      console.error("Error adding document: ", error);
    } finally {
      isSubmitting(false);
    }
  };

  const [selectedDealer, setSelectedDealer] = useState("");

  const [dealershipList, setdealershipList] = useState([]);
  const [contactList, setcontactList] = useState([]);
  const [dealerServiceList, setDealerServiceList] = useState([]);
  
  const [jobList, setJobList] = useState([]);
  const [jobDeatilsList, setJobDeatilsList] = useState([]);
  const [selectedJobDetails, setSelectedJobDeatils] = useState([]);

  const [jobServicesAmount, setjobServicesAmount] = useState(0);
  const [jobTotalAmount, setjobTotalAmount] = useState(0);


  const getJobserviceAmount = (selectedJobID) => {
    try {

      var subServiceAmount = 0;

      //console.log("Job Deatils", jobDeatilsList);

      if(jobDeatilsList.length > 0)
       {
        jobDeatilsList.forEach(item => {

          if(item.id === selectedJobID)
           {
              if(item.detail.sub_services)
              {
                item.detail.sub_services.forEach(subService => {

                  subServiceAmount += Number(subService.amount);

                });

              }
           } 

        });
       } 

      // const queryJobDetails = getDocs(collection(firestore, "Jobs",selectedJobID,'JobDetail'));
      // if(queryJobDetails.docs)
      // {
         
      //   queryJobDetails.docs.forEach(item => {
      //     if(item.data().sub_services)
      //     {
      //       item.data().sub_services.forEach(subService => {
      //         subServiceAmount += Number(subService.amount);
      //       });
      //     }  

      //   });
      // }
      
      return subServiceAmount;
      
    } catch (error) {
      
    }
  }

  const getcurrentJobServiceInfo = (serviceID) => {
    var service_name = "";
    dealerServiceList.forEach(service => {

      if(service.id === serviceID)
      {
        //console.log("Service name", service.name);
        service_name = service.name;
      }  

    });

    return service_name;

  }

  const updateJobsdetails = async()=>{
    try {

      var jobDetails = [];

    //console.log("Dealer Services", dealerServiceList);

    var total_amount = 0;

    selectedJobs.forEach(selJob => {

      jobList.forEach((job) => {

        if(selJob === job.id)
         {
          const serviceName = getcurrentJobServiceInfo(job.jobData.service);
          const serviceAmount =  getJobserviceAmount(job.id);

          //console.log("serviceAmount", serviceAmount);

          total_amount = Number(total_amount) + Number(serviceAmount);
          
          jobDetails.push({
            stock_number: job.jobData.stock_number,
            ro_number: job.jobData.ro_number,
            service_name: serviceName,
            serviceAmount: serviceAmount || 0,
           });

         } 
      });

    });

    setSelectedJobDeatils(jobDetails);

    
    console.log("total_amount", total_amount);

    setjobServicesAmount(Number(total_amount));

    if(invoiceDiscount)
    {
      total_amount = total_amount - invoiceDiscount;
    }


    setjobTotalAmount(total_amount);
    
    //console.log("jobDetails", jobDetails);
      
    } catch (error) {
      console.log(error);
    }
  }

  const getInvoiceInfo = async () => {

    try {

      const docRef = doc(firestore, "Invoices", invoiceID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        
        console.log("docSnap", docSnap.data());
        
        setInvoiceData(docSnap.data());

        if(docSnap.data().dealership)
         {
          setInvoiceDealer(docSnap.data().dealership);

         } 

         if(docSnap.data().discount)
         {
          setInvoiceDiscount(docSnap.data().discount);
         } 
        
         setInvoiceToDate(getDateTime(docSnap.data().to_date));
         setInvoiceFromDate(getDateTime(docSnap.data().from_date));


      } else {        
        console.log("No such document!");
      }

    } catch (error) {
      console.log("ProductView [Error]", error);
    }

  }

  const getAllDealers = async() => {

    const querySnapshot = await getDocs(collection(firestore, "Dealerships"));
    //console.log("Doc ID: ", querySnapshot.docs.map((doc) => doc.id) );
    const getDealership = querySnapshot.docs.map((doc, index) => {  
      return {
        id: doc.id,
        name: doc.data().name,
        address: doc.data().address,
        contact_person: doc.data().contact_person || [],
        is_disabled: doc.data().is_disabled,
        services: doc.data().services,
      }
    });
    setdealershipList(getDealership);
    setcontactList([]);
  };

  const updateJobList = async(dealerID) =>{

    try {
          //console.log(dealerID);
          setIsLoading(true);
          const q= query(collection(firestore, "Jobs"), and(where("dealership","==",dealerID), where("job_status","==", 5), where("is_invoice_exists","==", true), where("invoice_info.invoice_number", "==", invoiceData.invoice_number)));
          
          const querySnapshot = await getDocs(q);
          
          if(querySnapshot.docs.length > 0)
          {
            console.log("Doc ID: ", querySnapshot.docs.map((doc) => doc.data()) );

            let alljobDetails = [];
            const allJobsData = [];

            const selectjobIDS = [];

            // get jobs details
            for(const doc of  querySnapshot.docs)
            {
              const getJobDeatilsData = await getDocs(collection(firestore, "Jobs",doc.id,'JobDetail'));

              if(getJobDeatilsData.docs)
              {
                for(const detail of getJobDeatilsData.docs)
                {
                  //console.log("deatil", detail.data());
                  var subServicesAmount = 0;
                  
                  if(detail.data().sub_services)
                  {
                    detail.data().sub_services.forEach((subService) => {
                      subServicesAmount += Number(subService.amount);
                    });
                  } 

                  alljobDetails.push({id: doc.id, detail: detail.data(), jobAmount: subServicesAmount});
                  allJobsData.push({
                    jobData:doc.data(),
                    id: doc.id,
                  });

                  selectjobIDS.push(doc.id);
                                                  
                }  
              }
            }  

            setJobList(allJobsData);
            setJobDeatilsList(alljobDetails);

            //console.log(selectjobIDS);

            setSelectedJobs(selectjobIDS);

            setDeafultSelectedJobs(selectjobIDS);

            
          }

          setIsLoading(false);
          
          setButtonDisabled(false);

    } catch (error) {
      setIsLoading(false);
      setButtonDisabled(false);
      console.log("error [updateJobList]", error);
    }

  };

  const handleUpdateTotalamount = (e) => {
    try {
      
      setInvoiceDiscount(e.target.value);

      var total_amount = 0;

    selectedJobs.forEach(selJob => {

      jobList.forEach((job) => {

        if(selJob === job.id)
         {
          const serviceAmount =  getJobserviceAmount(job.id);
          total_amount = Number(total_amount) + Number(serviceAmount);
         } 
      });

    });

      setjobServicesAmount(Number(total_amount));
      setjobTotalAmount(Number(total_amount) - Number(e.target.value));

    } catch (error) {
      console.log("error", error);
    }
  }

useEffect(() => {

  if(invoiceID)
  {
    if(invoiceID === "")
      {
        navigate('/pages/invoice-list');
      }
      else if(invoiceID === undefined)
      {
        navigate('/pages/invoice-list');
      }
      else
      {
        getAllDealers();
        getInvoiceInfo();

        setButtonDisabled(true);

      } 

  }
  else
  {
    navigate('/pages/invoice-list');
  }  
  
}, []);
  
useEffect(() => {

  //console.log(selectedJobs.length);

  if(selectedJobs.length > 0)
  {    
    updateJobsdetails();
  } 
  else
  {
   // console.log("check");
    setSelectedJobDeatils([]);
    setjobTotalAmount(0);
    setInvoiceDiscount(0);
    setjobServicesAmount(0);

  }


}, [selectedJobs]);

useEffect(() => {
  updateJobList(invoiceDealer);
  if(dealershipList)
  {
    dealershipList.forEach(dealership => {
        if(dealership.id === invoiceDealer)
        {
          setSelectedDealer(dealership.id);          
        }  
    });
  }
}, [invoiceDealer]);


// Update Discount value in Invoice
const updateInvoiceDiscountInStatements = async (invDiscount) => {
  try {

    const statementRef = collection(firestore, "Statements");
    let q = query(statementRef, where("invoiceID","array-contains", invoiceID), orderBy("created_date", "desc"));

    const querySnapshot = await getDocs(q);
    if(querySnapshot.docs.length > 0)
    {
        const selectedStatementData =  querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            data: doc.data()
          };
        });
        
        console.log("selectedStatementData", selectedStatementData);

        for(const statement of selectedStatementData)
        {
          const invoiceToUpdate = statement.data.invoices.find( invoice => invoice.invoice_number === invoiceData.invoice_number);
          if(invoiceToUpdate)
          {
            invoiceToUpdate.discount = invDiscount;
          }

          await setDoc(doc(firestore,"Statements",statement.id), statement.data);

        }  
    
    }

    return true;

  } catch (error) {
    console.log("updateInvoiceDiscountInStatements [error]", error);
    return true;
  }
}

const initialValues = {
  invoiceNo: invoiceData.invoice_number,
  invoiceDate: invoiceFromDate,
  dueDate: invoiceToDate,
  dealershipName: "",
  contactPersonName: [],
  jobs:defaultSelectedJobs,
  discount: 0,
};
 
  return (
    <Container>
       <Container style={{ marginLeft: "0px" }}>
        <Breadcrumb routeSegments={[{ name: "All Invoices", path: "/invoice/invoice-list" }, { name: "Edit Invoice" }]} />
      </Container>

      <InvoiceRoot>
      <Card elevation={3}>
        <H4 p={2}>Edit Invoice</H4>
        <Divider sx={{ mb: 1 }} />

        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={invoiceSchema}>
          {({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
           setFieldValue,
          }) => (
            <form style={{ padding: "16px" }} onSubmit={handleSubmit}>
              <Grid container spacing={3} alignItems="center">
                <Grid item md={2} sm={4} xs={12}>
                  Invoice Number
                </Grid>

                <Grid item md={10} sm={8} xs={12}>
                  <StyledInput
                    size="small"
                    name="invoiceNo"
                    variant="outlined"
                    disabled={true}
                    onChange={handleChange}
                    value={values.invoiceNo}
                  />
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  Invoice Date
                </Grid>

                <Grid item md={10} sm={8} xs={12}>
                  <Box m={-1} display="flex" flexWrap={1}>
                    <StyledDatePicker
                      value={values.invoiceDate}
                      label="Invoice Date"
                      inputFormat="MMMM dd, yyyy"
                      disabled={false}
                      onChange={(date) => setFieldValue("invoiceDate", date)}
                      renderInput={(props) => (
                        <StyledInput
                          {...props}
                          variant="outlined"
                          label="Invoice Date"
                          sx={{ m: "8px !important" }}
                        />
                      )}
                    />

                    <StyledDatePicker
                      name="dueDate"
                      value={values.dueDate}
                      label="Due date"
                      inputFormat="MMMM dd, yyyy"
                      onChange={(date) => setFieldValue("dueDate", date)}
                      renderInput={(props) => (
                        <StyledInput
                          {...props}
                          label="Due Date"
                          variant="outlined"
                          sx={{ m: "8px !important" }}
                        />
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item md={2} sm={4} xs={12} style={{display:"none"}}>
                  Dealership Name
                </Grid>

                <Grid item md={10} sm={8} xs={12} style={{display:"none"}}>
                  <StyledInput
                    select
                    label="Name"
                    size="small"
                    id="inputField"
                    variant="outlined"
                    name="dealershipName"
                    disabled={true}
                    value={selectedDealer || ""}>
                    {dealershipList?.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </Grid>

                <Grid item md={2} sm={4} xs={12} style={{display:"none"}}>
                Contact Person Name
                </Grid>

                <Grid item md={10} sm={8} xs={12} style={{display:"none"}}>
                  <StyledInput
                    select
                    size="small"
                    variant="outlined"
                    name="contactPersonName"
                    onChange={handleChange}
                    label="Contact Person Name"
                    value={values.contactPersonName || ""}>
                    {contactList.map((item, ind) => (
                      <MenuItem value={item.contract_number} key={item.contract_number}>
                        {item.email ? item.email: item.phone}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </Grid>


                <Grid item md={2} sm={4} xs={12} style={{display:"none"}}>
                  Pick Jobs
                </Grid>
                <Grid item md={10} sm={8} xs={12} style={{display:"none"}}>
                  <FormControl sx={{width:"25%"}}  variant="outlined" error={Boolean(touched.jobs && errors.jobs)}>
                    <InputLabel>Pick Jobs</InputLabel>
                    <Select
                      multiple
                      size="small"
                      value={values.jobs || []}
                      name="jobs"
                      label="Pick Jobs"
                      disabled={true}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        setSelectedJobs(value)
                        setFieldValue("jobs", typeof value === "string" ? value.split(", ") : value);
                      }}
                      renderValue={(selected) => selected.join(", ")}
                      
                    >
                      {jobList.map((job) => (
                        <MenuItem key={job.id} value={job.id} >
                          <Checkbox checked={values.jobs.indexOf(job.id) > -1} />
                          <ListItemText primary={job.jobData.stock_number !== "" && job.jobData.stock_number !== undefined && job.jobData.stock_number !== null ? job.jobData.stock_number : job.jobData.tag_number !== "" && job.jobData.tag_number !== undefined && job.jobData.tag_number !== null ? job.jobData.tag_number : job.jobData.ro_number} />                          
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{touched.jobs && errors.jobs}</FormHelperText>
                    {isLoading ? <Loading /> : ""}
                  </FormControl>
                </Grid>

                {selectedJobDetails.length > 0 && (
                  <Grid item xs={12}>
                  <Box overflow="auto">
                      <Table sx={{ whiteSpace: "pre", minWidth: 750 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={7}>Job Name</TableCell>
                            <TableCell colSpan={2}>Services Performed</TableCell>
                            <TableCell colSpan={2} align="center">
                              Amount
                            </TableCell>
                          </TableRow>
                        </TableHead>
            
                        <TableBody>
                          {selectedJobDetails?.map((item, ind) => (
                            <TableRow key={ind} sx={{ position: "relative" }}>                 
                              <StyledCell colSpan={7} align="left">
                                <FlexBox>
                                  <h5>{item.stock_number !== "" && item.stock_number !== undefined && item.stock_number !== null ? item.stock_number :  item.tag_number !== "" && item.tag_number !== undefined && item.tag_number !== null ? item.tag_number : item.ro_number}</h5>
                                </FlexBox>
                              </StyledCell>
            
                              <StyledCell colSpan={2} align="left">
                                <h5>{item.service_name}</h5>
                              </StyledCell>            
            
                              <StyledCell colSpan={2} align="center">
                              {Number(item.serviceAmount).toFixed(2)}
                              </StyledCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                  </Box>
                  </Grid>                  
                 
                )}

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>

              <Box mb={4}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      rows={6}
                      fullWidth
                      multiline
                      name="notes"
                      size="small"
                      variant="outlined"
                      label="Additional Notes"
                      value={values.notes}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledCard elevation={0}>
                      <Grid container spacing={3} justify="space-between" alignItems="center">
                        <Grid item xs={6}>
                          Sub Total
                        </Grid>

                        <Grid item xs={6}>
                          <Box textAlign="right">{Number(jobServicesAmount).toFixed(2)}</Box>
                        </Grid>

                         <Grid item xs={6}>
                          Discount ($)
                        </Grid>

                        <Grid item xs={6}>
                          <Box textAlign="right">
                          <StyledInput
                            size="small"
                            name="discount"
                            variant="outlined"
                            onChange={handleUpdateTotalamount}
                            value={invoiceDiscount || 0}
                          />
                          </Box>
                        </Grid> 

                        <Grid item xs={6}>
                          <H4 sx={{ m: 0 }}>Total ( $ )</H4>
                        </Grid>

                        <Grid item xs={6}>
                          <Box textAlign="right">
                            <H4 sx={{ m: 0 }}>{Number(jobTotalAmount).toFixed(2)}</H4>
                          </Box>
                        </Grid>
                      </Grid>
                    </StyledCard>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={3}>
                <FlexBox gap={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={buttonDisabled}
                    type="submit"
                  >
                    Update
                  </Button>
                </FlexBox>
              </Box>
            </form>
          )}
        </Formik>
      </Card>
    </InvoiceRoot>
    
    </Container>


            
  );
}

const invoiceSchema = yup.object().shape({
  //servicesList: yup.string().required("Pick atleast one service"),
  //subservicesList: yup.string().required("Pick atleast one Sub-Service"),
  //dealershipList: yup.string().required("Dealership is required"),
  //vinNumber: yup.mixed().required("VIN Number is required"),
  //stockNumber: yup.mixed().required("Stock Number is required"),
});

