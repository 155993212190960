import { DeleteOutline, Preview, QrCode, UnarchiveOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  styled
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// GLOBAL CUSTOM COMPONENTS
import Breadcrumb from "app/components/Breadcrumb";
import { H5 } from "app/components/Typography";
import { TableHead, TableToolbar } from "app/components/data-table";
import { getComparator, stableSort } from "app/components/data-table/utils";
// GLOBAL CUSTOM HOOKS
import { firestore } from "app/firebase"; // Assuming Firebase set up
import useTable from 'app/hooks/useTable'; // Import the useTable hook from its source file

import Edit from "@mui/icons-material/Edit";
import axios from "axios";
import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";



// STYLED COMPONENTS
// 
// const IMG = styled("img")({
//   height: 32,
//   borderRadius: "4px"
// });
const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center"
});

// const Container = styled("div")(({ theme }) => ({
//   margin: "30px",
//   [theme.breakpoints.down("sm")]: { margin: "16px" },
//   "& .breadcrumb": {
//     marginBottom: "30px",
//     [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
//   }
// }));

// STYLED COMPONENTS
const Container = styled(Box)(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));



 
export default function ArchivedDealershipList() {
  const {
        //page,
        order,
        pageorderBy,
        selected,
        //rowsPerPage,
    
        isSelected,
        handleClick,
        //handleChangePage,
        handleRequestSort,
        handleSelectAllClick,
        //handleChangeRowsPerPage
      } = useTable({ defaultOrderBy: "name" });

  //const customers = [];
  const [dealerships, setDealerships] = useState([]);
  const [dealershipNames, setDealershipNames] = useState([]);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedDealership, setSelectedDealership] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const dataQuery = new URLSearchParams(window.location.search);
  const filteredDealershipgroupName = dataQuery.get('groupName') || "";
  const filteredDealershipName = dataQuery.get('dealershipName') || "";

  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [groupNames, setGroupNames] = useState([]);
  const [dealershipList, setDealershipList] = useState([]);
  const [selectedDealershipsList, setSelectedDealershipsList] = useState([]);

  const fetchGroupNames = async () => {
    const dealerRef = collection(firestore, "Dealerships");
    const q = query(dealerRef, orderBy("group_name", "asc"));
    const querySnapshot = await getDocs(q);
    const names = querySnapshot.docs.map((doc) => doc.data().group_name);
    const uniqueNames = [...new Set(names)];
    setGroupNames(uniqueNames);
   };
  
  const fetchDealers = async () => {
    
    const dealerRef= collection(firestore, "Dealerships");
    let q = query(dealerRef, where("is_disabled","==", true),orderBy("name", "asc"));

    if (selectedDealership) {
      q = query(q, where("name", "==", selectedDealership));
    }

    if (selectedGroupName) {
      q = query(q, where("group_name", "==", selectedGroupName));
    }

    const querySnapshot = await getDocs(q);
    //const customerData = querySnapshot.docs.map((doc) => doc.data());
    const getDealersData = querySnapshot.docs.map((doc) => {

      const sortedItems = doc.data().services.sort((a, b) => a.id - b.id);

      return {
        name: doc.data().name ,
        address: doc.data().address,
        id: doc.id,
        services: sortedItems,
        phone: doc.data().phone,
        website:doc.data().website,
        group_name: doc.data().group_name || "",
        data: doc.data(),
      };
    });
    
    setDealerships(getDealersData);

  };

  const fetchDealershipNames = async () => {
    const dealerRef = collection(firestore, "Dealerships");
    const q = query(dealerRef, where("is_disabled","==", true),orderBy("name", "asc"));
    const querySnapshot = await getDocs(q);
    const allDealerships = querySnapshot.docs.map((doc) => doc.data());
    const names = querySnapshot.docs.map((doc) => doc.data().name);
    const uniqueNames = [...new Set(names)];
    setDealershipNames(uniqueNames);
    setSelectedDealershipsList(uniqueNames);
    setDealershipList(allDealerships);
  };

  useEffect(() => {
    fetchDealershipNames();
    fetchGroupNames();
  }, []);

  useEffect(()=>{
    if(filteredDealershipName !== "")
    {
      console.log(filteredDealershipName);
      
      setSelectedDealership(filteredDealershipName);
    }
      
    if(filteredDealershipgroupName !== "")
    {
      setSelectedGroupName(filteredDealershipgroupName);
    }
   
   },[filteredDealershipName,filteredDealershipgroupName]);


   useEffect(() => {
    fetchDealers();

    if(selectedGroupName)
      {
        const selecteddealershipsByGroupName = dealershipList.filter(dealer => dealer.group_name === selectedGroupName);
        const currentSelectedDealershipsName = selecteddealershipsByGroupName.map(item => {
          return item.name;
        });
        setSelectedDealershipsList(currentSelectedDealershipsName);
      }
      else
      {
        const names = dealershipList.map((doc) => doc.name);
        const uniqueNames = [...new Set(names)];
        setDealershipNames(uniqueNames);
        setSelectedDealershipsList(uniqueNames);
      }
    setPage(0);
  }, [selectedDealership, selectedGroupName]);


  const filtersApplied = selectedDealership;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rowsPerPage changes
  };

  
  const handleResetFilters = () => {
    setSelectedDealership("");
  };

  const navigate = useNavigate();
  
  const columns = [
    { id: "group_name", align: "left", disablePadding: true, label: "Group Name" },
    { id: "name", align: "left", disablePadding: true, label: "Name" },
    { id: "phone", align: "left", disablePadding: true, label: "Phone" },    //Added for testing
    { id: "services", align: "left", disablePadding: true, label: "Services" },    //Added for testing
    { id: "address", align: "left", disablePadding: true, label: "Address" },    //Added for testing    
    { id: "website", align: "left", disablePadding: true, label: "Website URL" },    //Added for testing
    //{ id: "address", align: "center", disablePadding: false, label: "Address" },
    //{ id: "phone", align: "center", disablePadding: false, label: "Phone" },
    //{ id: "location", align: "center", disablePadding: false, label: "Location" },
   // { id: "employeeType", align: "center", disablePadding: false, label: "Employee Type" },
    { id: "edit", align: "center", disablePadding: false, label: "Actions?" }
  ];

  const handleSelectAllRows = (event) => {
    const newSelected = dealerships.map((n) => n.name);
    handleSelectAllClick(event.target.checked, newSelected);
  };

  // Delete Dealership
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDealershipID, setDeleteDealershipID] = useState("");
  
 
  // Delete Dealerships Callbacks

  const handleDeleteDealership = (dealershipID) => {
    try {
      setDeleteDealershipID("");

      setDeleteDealershipID(dealershipID);
      setDeleteDialogOpen(true);
      
    } catch (error) {
      
    }
  }

  const handleCancelDelete = () => {
    try {

      setDeleteDealershipID("");
      setDeleteDialogOpen(false);
      
    } catch (error) {
      
    }
  }

  const handleConfirmDelete = async(dealershipID) => {
    try {

      const jobDocRef = doc(firestore, 'Dealerships', dealershipID);

      // Delete Subservices

      const subServiceRef = collection(firestore, 'Dealerships', dealershipID, 'SubServices');              
      const allDocs = await getDocs(subServiceRef);
      allDocs.docs.forEach(async(doc) => {
        await deleteDoc(doc.ref);
      });

      await deleteDoc(jobDocRef);
  

      let filtered = [...dealerships];

      filtered = filtered.filter(dealership => dealership.id !== dealershipID);

      setDealerships(filtered);
      
      
      setDeleteDealershipID("");
      setDeleteDialogOpen(false);

      enqueueSnackbar("Dealership successfully Deleted", { variant: "success" }); 
      
    } catch (error) {
      console.log("Error", error.message);
    }
  }
  
 // Download QR Code
 const handleDownloadQRCode = async (dealership) => {
  try {
    //console.log("Dealership", dealership);
  
    // Fetch latitude and longitude from address using geocoding API
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address: dealership.address,
        key: 'AIzaSyATTkXUewHkTqNIOleedZ6tYwCQstrFfrc' // Replace with your Google Maps API key
      }
    });

    //console.log(response);

    // Extract latitude and longitude from response
    const { results } = response.data;

    if (results.length > 0) {
      const { lat, lng } = results[0].geometry.location;

      // Construct QR code data
      const qrCodeData = `ID: ${dealership.id}\nLatitude: ${lat}\nLongitude: ${lng}`;
      // Convert QR code data to a URL-encoded format
      const qrCodeDataURL = encodeURIComponent(qrCodeData);

      // Create temporary link and trigger download
      const link = document.createElement('a');
      link.href = `data:image/png;base64,${qrCodeDataURL}`;
      link.download = dealership.name.replace(" ", "_")+'_QRCode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    } else {
      console.error('No results found for the address.');
    }
    
  } catch (error) {
    
  }
 }


 // Un-Archived Confirmation
 const [unArchiveDialogOpen, setUnArchiveDialogOpen] = useState(false);
 const [unArchiveDealerID, setUnArchiveDealerID] = useState("");
 const [unArchiveDealerIndex, setUnArchiveDealerIndex] = useState("");
 const [unArchiveDealerItem, setUnArchiveDealerItem] = useState("");

 const handleConfirmUnArchive = async(dealerID, index, record) => {

   try {

     await setDoc(doc(firestore, "Dealerships", dealerID), { ...record, is_disabled: false });

     let filtered = [...dealerships];

     filtered = filtered.filter(dealership => dealership.id !== dealerID);

     setDealerships(filtered);

     enqueueSnackbar("Dealership successfully Un-Archived", { variant: "success" }); 

     setUnArchiveDialogOpen(false);
     setUnArchiveDealerID("");
     setUnArchiveDealerIndex("");
     setUnArchiveDealerItem("");
     
   } catch (error) {
     
   }
   
   
 };

 const handleCancelUnArchive = () => {
   try {

     setUnArchiveDialogOpen(false);
     setUnArchiveDealerID("");
     setUnArchiveDealerIndex("");
     setUnArchiveDealerItem("");

   } catch (error) {
     
   }
 };

 const handleUnArchiveDealer = async(dealerID, index, record) => {
  try {

    setUnArchiveDealerID("");
    
    setUnArchiveDialogOpen(true);
    setUnArchiveDealerID(dealerID);
    setUnArchiveDealerIndex(index)
    setUnArchiveDealerItem(record);

    
  } catch (error) {
    
  }
}
 
  return (
    <Container>

      <Container style={{marginLeft:"0px"}}>
          <Breadcrumb routeSegments={[{ name: "Archived Dealerships" }]}/>
      </Container>

      <Container style={{marginLeft:"0px"}}>

        <FormControl variant="outlined" sx={{ minWidth: 200, marginBottom: 2, marginRight: 2 }}>
          <InputLabel id="group-select-label">Filter by Group</InputLabel>
          <Select
            labelId="group-select-label"
            label="Filter by Group"
            value={selectedGroupName}
            onChange={(e) => setSelectedGroupName(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {groupNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 200, marginBottom: 2, marginRight: 2 }}>
          <InputLabel id="dealership-select-label">Filter by Name</InputLabel>
          <Select
            labelId="dealership-select-label"
            label="Filter by Name"
            value={selectedDealership}
            onChange={(e) => setSelectedDealership(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {selectedDealershipsList.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        {filtersApplied && (
        <Button variant="contained"  sx={{ m: 2}} onClick={handleResetFilters}>Clear Filters</Button>
        )}
      </Container>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar title="Archived Dealerships" />

        <TableContainer>
          <Table sx={{ minWidth: 1200 }}>
            <TableHead
              order={order}
              orderBy={pageorderBy}
              headCells={columns}
              numSelected={selected.length}
              rowCount={dealerships.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllRows}
            />

            <TableBody>
              {stableSort(dealerships, getComparator(order, pageorderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      onClick={(event) => handleClick(event, row.name)}>
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected}  style={{display:"none"}}/>
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none" style={{wordBreak:"unset"}}>
                        <FlexBox gap={1}>
                          {/* <IMG src={row.imgUrl} alt="user" /> */}
                          <H5 fontSize={15} style={{textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden",wordBreak:"unset"}}>{row.group_name? row.group_name: ""}</H5>
                        </FlexBox>
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none" style={{wordBreak:"unset"}}>
                        <FlexBox gap={1}>
                          {/* <IMG src={row.imgUrl} alt="user" /> */}
                          <H5 fontSize={15} style={{wordBreak:"unset", color:"#03a9f4"}}><a href={`/pages/view-dealership?dealerID=`+row.id}>{row.name}</a></H5>
                        </FlexBox>
                      </TableCell>

                      <TableCell align="left" style={{wordBreak:"unset"}}>{row.phone? row.phone: ""}</TableCell>

                      <TableCell align="left" style={{wordBreak:"unset"}}>
                        <ul align="left" style={{listStyle:"dot", paddingLeft:"0"}}>
                      {row.services.map((item, index) =>(
                          ((()=>{
                                return(
                                  <li key={index+15} >{item.name}</li>
                                );
                          
                          })())
                                
                      ))}
                        
                        </ul></TableCell>

                      <TableCell align="left" style={{wordBreak:"unset"}}>{row.address}</TableCell>

                      <TableCell align="left" style={{textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden",wordBreak:"unset"}}>{row.website? <a href={row.website} target="_blank">{row.website}</a> : "" }
                        </TableCell>

                      <TableCell align="center" style={{wordBreak:"unset"}}>
                      
                      <IconButton onClick={() => navigate(`/pages/view-dealership?dealerID=`+row.id)} > 
                        
                        <Preview fontSize="small"/> 

                        </IconButton>

                        <IconButton onClick={()=> navigate(`/pages/edit-dealership?dealerID=`+row.id)} style={{display:"none"}}>
                          <Edit fontSize="small" />
                        </IconButton>

                        <IconButton onClick={(event) => handleDownloadQRCode(row) }  color="info" style={{display:"none"}}>
                          <QrCode fontSize="small" />
                        </IconButton>

                        <IconButton onClick={(event) => handleUnArchiveDealer(row.id, index, row.data)} color="warning" title="Un-Archive Dealer">
                          <UnarchiveOutlined fontSize="small"/>
                        </IconButton>

                        <IconButton onClick={(event) => handleDeleteDealership(row.id)} color="error" >
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                        
                    </TableCell>
{/*
                      <TableCell align="center">
                      <IconButton component={Link} to={`/pages/dealerships/dealership-viewer`}>
                          <Edit />
                        </IconButton>

                      <IconButton onClick={() => navigate("/pages/new-customer")}>
                        <TrendingFlat />
                      </IconButton>
                    </TableCell>
 */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={dealerships.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to permanently delete this Dealership?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={(event) => handleConfirmDelete(deleteDealershipID)} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={unArchiveDialogOpen}
          onClose={() => setUnArchiveDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure you want to Un-Archive this dealership?`}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCancelUnArchive} color="primary">
              Cancel
            </Button>
            <Button onClick={(event) => handleConfirmUnArchive(unArchiveDealerID, unArchiveDealerIndex, unArchiveDealerItem)} color="primary" autoFocus>
              Un-Archive
            </Button>
          </DialogActions>
        </Dialog>

    </Container>
  );
}
