import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from "@mui/icons-material/Clear";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function OtherDetailsForm({ values, handleChange }) {
  const [ssnFiles, setSsnFiles] = useState([]);
  const [licenseFiles, setLicenseFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [terminationDate, setterminationDate] = useState("none");

  const handleContractChange = (field, value) => {
    handleChange({ target: { name: field, value } });
  };

  const handleOngoingChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      handleContractChange("contractStart", null);
      handleContractChange("contractEnd", null);
    } else {
      handleContractChange("contractStart", new Date());
      handleContractChange("contractEnd", new Date());
    }
    handleContractChange("ongoingContract", checked);
  };

  const { getRootProps: getRootPropsSSN, getInputProps: getInputPropsSSN, open: openSSN } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setSsnFiles(prevFiles => [...prevFiles, ...newFiles]);
    },
  });

  const { getRootProps: getRootPropsLicense, getInputProps: getInputPropsLicense, open: openLicense } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setLicenseFiles(prevFiles => [...prevFiles, ...newFiles]);
    },
  });

  const handleRemoveFile = (fileType, fileToRemove) => {
    const confirmRemove = window.confirm("Are you sure you want to remove this file?");
    if (!confirmRemove) return;

    if (fileType === 'ssn') {
      setSsnFiles(ssnFiles.filter(file => file !== fileToRemove));
    } else if (fileType === 'license') {
      setLicenseFiles(licenseFiles.filter(file => file !== fileToRemove));
    } else if (fileType === 'other') {
      setOtherFiles(otherFiles.filter(file => file !== fileToRemove));
    }
  };

  const handleTerminationDate = async(e) => {
    try {
      //console.log(e.target.checked);
      if(e.target.checked)
      {
        setterminationDate("display");
      }
      else
      {
        setterminationDate("none");
      }  

    } catch (error) {
      
    }
  }

  useEffect(()=>{

    values.ssnFiles = ssnFiles;

  },[ssnFiles]);


  useEffect(()=>{

    values.licenseFiles = licenseFiles;

  },[licenseFiles]);



  const renderFilePreview = (file, fileType) => (
    <div key={file.name} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <img
        src={file.preview}
        alt={file.name}
        style={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
      />
      <div>
        <a href={file.preview} target="_blank" rel="noopener noreferrer">
          {file.name}
        </a>
        <Button onClick={() => handleRemoveFile(fileType, file)}>Remove</Button>
      </div>
    </div>
  );

  return (
    
    <Grid container spacing={3} alignItems="center">
      
      <Grid item md={2} sm={4} xs={12}>
        Social Security Number
      </Grid>
      <Grid item md={10} sm={8} xs={12}>
        <TextField
          size="small"
          label="Social Security Number"
          variant="outlined"
          required
          name="social_security_number"
          value={values.social_security_number || ""}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div {...getRootPropsSSN()}>
                  <input {...getInputPropsSSN()} />
                  <IconButton onClick={openSSN}>
                    <AttachFileIcon />
                  </IconButton>
                </div>
              </InputAdornment>
            ),
          }}
        />
        {ssnFiles.map(file => renderFilePreview(file, 'ssn'))}
      </Grid>

      <Grid item md={2} sm={4} xs={12}>
        Driver's License
      </Grid>
      <Grid item md={10} sm={8} xs={12}>
        <TextField
          size="small"
          name="license"
          label="Driver's license"
          variant="outlined"
          required
          value={values.license}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div {...getRootPropsLicense()}>
                  <input {...getInputPropsLicense()} />
                  <IconButton onClick={openLicense}>
                    <AttachFileIcon />
                  </IconButton>
                </div>
              </InputAdornment>
            ),
          }}
        />
        {licenseFiles.map(file => renderFilePreview(file, 'license'))}
      </Grid>

      <Grid item md={2} sm={4} xs={12}>
        Pay Rate
      </Grid>
      <Grid item md={10} sm={8} xs={12}>
        <TextField
          size="small"
          name="hourly_rate"
          label="Hourly Rate ($)"
          variant="outlined"
          value={values.hourly_rate || ""}
          onChange={handleChange}
          style={{ marginRight:'12px'}}
        />
      </Grid>

      <Grid item md={2} sm={4} xs={12} >
        Employment Start Date
      </Grid>
      <Grid item md={10} sm={8} xs={12}>
        <DatePicker
          onChange={(date) => handleContractChange("contractStart", date)}
          value={values.contractStart}
          calendarIcon={<DateRangeIcon />}
          clearIcon={<ClearIcon />}
          format="MM/dd/yyyy"
          className="date-picker"
        />
      </Grid>

      <Grid item md={2} sm={4} xs={12} >
        Do you want Termination date?
      </Grid>

      <Grid item md={10} sm={8} xs={12}>
        <Checkbox onClick={(event) => handleTerminationDate(event)} />
      </Grid>

      <Grid item md={2} sm={4} xs={12} display={terminationDate}>
        Employment Termination Date
      </Grid>
      <Grid item md={10} sm={8} xs={12} display={terminationDate}>
        <DatePicker
          onChange={(date) => handleContractChange("contractEnd", date)}
          value={values.contractEnd}
          disabled={values.ongoingContract}
          calendarIcon={<DateRangeIcon />}
          clearIcon={<ClearIcon />}
          format="MM/dd/yyyy"
          minDate={new Date()}
          className="date-picker"
        />
      </Grid>

      <Grid item xs={12} style={{ display: "none" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.ongoingContract}
              onChange={handleOngoingChange}
              color="primary"
            />
          }
          label="Ongoing Contract"
        />
      </Grid>
    </Grid>
  );
}
